import config from "./config";

export const url = {
  LOGIN_URL: "api/front/auth/login",
  SIGNUP_URL: "api/front/auth/register",
  LOGOUT_URL: "api/front/auth/logout",
  GET_USER_DETAILS_URL: "api/front/auth/me",
  RESET_PASSWORD_URL: "api/front/auth/reset",
  FORGOT_PASSWORD_URL: "api/front/auth/forget",
  GET_ALLCLIENTS_URL: "api/front/get-all-client",
  LOGIN_WITH_GOOGLE_URL: "api/front/login/google",
  CLIENT_GET_BASE64_URL : "api/front/imageBase64",
  CLIENT_GET_DOOR_STYLE : "api/front/get-door-style/",
  CLIENT_REQUEST_STEP_1_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_2_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_3_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_4_URL: "api/front/client-request",
  CLIENT_REQUEST_STEP_5_URL: "api/front/client-request",
  DESIGNER_ALLREQUEST_URL: "api/front/designer-all-request",
  SET_PASSWORD_URL: "api/front/client/login/invitation/verify",
  UPLOAD_COMMENT_MEDIA : "api/front/client-request/uploadFile",
  GET_HISTORY_DATA_URL: "api/front/request-history-management",
  CLIENT_REQUEST_DELETE_URL: "api/front/client-request/remove",
  CLIENT_REQUEST_UPDATE_STATUS_URL: "api/front/request-status",
  CLIENT_REQUEST_SEARCH_URL: "api/front/client-request-search",
  CLIENT_REQUEST_DETAIL_VIEW: "api/front/client-request-detail",
  CLIENT_REQUEST_COPY_URL: "api/front/client-request-replicate",
  CLIENT_REQUEST_STEP_3_GET_URL: "api/front/client-request/show",
  UPDATE_USER_PROFILE_INFORMATION : "api/front/my-profile-update",
  LOGIN_WITH_GOOGLE_CALLBACK_URL: "api/front/login/google/callback",
  DESIGNER_MYOPEN_REQUEST_URL: "api/front/designer-my-open-request",
  CLIENT_REQUEST_LISTING_DRAFT_URL: "api/front/client-request-draft",
  DESIGNER_UPDATE_WORKLOG_URL: "api/front/designer-request-work-log",
  CLIENT_REQUEST_COMMENT_RATING_URL: "api/front/client-request-rating",
  DESIGNER_UPDATE_ASSIGNEE_URL: "api/front/assign-request-to-designer",
  CLIENT_REQUEST_ADD_COMMENT_URL: "api/front/client-request-add-comment",
  DESIGNER_DELIVERED_REQUEST_URL: "api/front/designer-request-delivered",
  CLIENT_REQUEST_GET_DISCUSSION_URL: "api/front/client-request-discussion",
  CLIENT_REQUEST_KITCHEN_MEDIA_UPLOAD_URL: "api/front/client-media-upload",
  CLIENT_REQUEST_STEP_5_MEDIA_URL: "api/front/client-request/stepFiveMedia",
  DESIGNER_REQUEST_ADD_COMMENT_URL: "api/front/designer-request-add-comment",
  CLIENT_REQUEST_DETAIL_UPDATE_URL: "api/front/client-request-detail-update",
  CLIENT_REQUEST_LISTING_YOURQUEUE_URL: "api/front/client-request-your-queue",
  CLIENT_REQUEST_DELETE_COMMENT_URL: "api/front/client-request-delete-comment",
  CLIENT_REQUEST_UPDATE_COMMENT_URL: "api/front/client-request-update-comment",
  DESIGNER_REQUEST_GET_DISCUSSION_URL: "api/front/designer-request-discussion",
  DESIGNER_REQUEST_UPDATE_STATUS_URL:"api/front/designer-request-status-change",
  DESIGNER_REQUEST_UPDATE_COMMENT_URL:"api/front/designer-request-update-comment",
  DESIGNER_REQUEST_DELETE_COMMENT_URL:"api/front/designer-request-delete-comment",
  CLIENT_REQUEST_LISTING_DESIGN_DELIVERED_URL:"api/front/client-request-delivered",
  CLIENT_REPORT_DETAILS: "api/front/client-report",
  CONTACTUS_URL: "api/front/contactus",
  CHANGE_PASSWORD:"api/front/change-password",
  ACCOUNT_INFO_UPDATE:"api/front/account-info-update",
  GET_ACCOUNT_INFO:"api/front/get-account-info",
  CLIENT_ADDRESS:"api/front/client-address",// ADD GETLIST DELETE EDIT UPDATE
  GET_COUNTRY_LIST:"api/front/client-address/create", 
  SET_DEFAULT_ADDRESS:"api/front/set-default-address",
  SAVED_CARDS:"api/front/saved-cards"
};

export const reportUrls ={
  GET_CLIENT_PRODUCTIVITY_RATIO : 'api/front/report/client/productivity-ratio',
  DESIGNER_REPORT_DETAILS: "api/front/designer-report",
  GET_DESIGNER_PRODUCTIVITY_RATIO : 'api/front/report/designer/productivity-ratio',
  GET_CLIENT_TOTAL_REQUEST_SUBMITTED : 'api/front/report/client/total-request-submitted',
  GET_DESIGNER_TOTAL_REQUEST_SUBMITTED : 'api/front/report/designer/total-request-submitted'
}

const apiUrl = (name) => {
  return `${config.API_ENDPOINT}${url[name]}`;
};

export const getApiUrl = (path,params) => `${config.API_ENDPOINT}${path}${params}`

export default apiUrl;
