/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import MyRequestAddRequest from '../MyRequestAddRequest'
import { convertUTCToLocale } from '../../Utils/utils'
import { Link } from "react-router-dom";

import "./index.scss";

function RequestListItem({
  ratingCopyTimeSpentFlag,
  data,
  loadMoreRequest,
  viewRequest,
  viewRequestUrl,
  hideDeleteIcon,
  deleteRequest,
  copyClientRequest,
  requestStatusSection,
  draftRequestClick,
}) {
  const { user } = useSelector((state) => state.auth);
  const isViewRequestUrlFunction = useMemo(() => typeof viewRequestUrl === 'function', [viewRequestUrl]);
  const loadMoreRecords = () => {
    loadMoreRequest();
  };
  const viewRequestNew = (e) => {
    viewRequest(e.target.id);
  };
  const deleteRequestFromList = (e) => {
    deleteRequest(e.target.id);
  };
  const copyRequest = (e) => {
    copyClientRequest(e.target.id);
  };
  const draftClick = (e) => {
    draftRequestClick(e.target.id);
  };
  const renderRatingStar = (ratingCount) => {
    const ratingArray = [0, 0, 0, 0, 0];
    for (let i = 0; i < ratingArray.length; i++) {
      if (i < ratingCount) {
        ratingArray[i] = 1;
      } else {
        ratingArray[i] = 0;
      }
    }

    return (
      <>
        {ratingArray.map((data, index) => {
          return (
            <span
              key={index}
              className={data === 1 ? "star active" : "star"}
            ></span>
          );
        })}
      </>
    );
  };

  const isDraftSection = Boolean(requestStatusSection === "yourdrafts");

  const getItemContent = (key) => (
    <div
      onClick={
        isDraftSection
          ? draftClick
          : viewRequestNew
      }
      style={{ cursor: "pointer" }}
      id={data.datatable.data[key]["clientRequestId"]}
      className="request-detail"
    >
      <div
        id={data.datatable.data[key]["clientRequestId"]}
        className="title-and-comment"
      >
        <div className="request-img">
          <img
            onClick={
              isDraftSection
                ? draftClick
                : viewRequestNew
            }
            id={data.datatable.data[key]["clientRequestId"]}
            src={data.datatable.data[key]["request_type_image"]}
            alt="CP2020"
          />
        </div>
        <h2
          id={data.datatable.data[key]["clientRequestId"]}
          className="title"
        >
          {data.datatable.data[key]["requestTitle"]}
        </h2>
        <ul
          id={data.datatable.data[key]["clientRequestId"]}
          className="comment-and-revision"
        >
          <li id={data.datatable.data[key]["clientRequestId"]}>
            <a
              id={data.datatable.data[key]["clientRequestId"]}
              href="#"
              title={
                data.datatable.data[key]["requestTotalComments"]
                  ? data.datatable.data[key][
                  "requestTotalComments"
                  ]
                  : 0 + " Comments"
              }
            >
              {data.datatable.data[key]["requestTotalComments"]
                ? data.datatable.data[key][
                "requestTotalComments"
                ]
                : 0}{" "}
              Comments
            </a>
          </li>
          <li>
            <a
              href="#"
              id={data.datatable.data[key]["clientRequestId"]}
              title={
                data.datatable.data[key][
                "requestRevisionNumber"
                ] + " Revisions"
              }
            >
              {
                data.datatable.data[key][
                "requestRevisionNumber"
                ]
              }{" "}
              Revisions
            </a>
          </li>
        </ul>
      </div>
      <div
        className="name-and-status"
        id={data.datatable.data[key]["clientRequestId"]}
      >
        <div className="name-container">
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
            style={{
              display: user.group_id == 3 ? "" : "none",
            }}
          >
            Assigned to:{" "}
            {data.datatable.data[key]["assignedTo"] &&
              data.datatable.data[key]["assignedTo"]
              ? data.datatable.data[key]["assignedTo"]
              : ""}
          </span>
          <span
            id={data.datatable.data[key]["clientRequestId"]}
            className="request-added"
          >
            Added By: {data.datatable.data[key]["requestAddedBy"]}
          </span>
        </div>
        <span
          id={data.datatable.data[key]["clientRequestId"]}
          className={
            "request-status " +
            (data.datatable.data[key]["requestStatus"]
              ? data.datatable.data[key]["requestStatus"]
              : ""
            )
              .toLowerCase()
              .replace(/\s+/g, "-")
          }
        >
          {data.datatable.data[key]["requestStatus"]}
        </span>
      </div>
    </div>
  );

  return (
    <div className="requestlistitem">
      <div className="container">
        <ul className="requestlis">
          {data !== "" && data?.datatable && data?.datatable?.data.length !== 0
            && Object.keys(data.datatable.data).map((key, index) => {
              return (
                <li key={key}>
                  <div className="about-request cf">
                    <WrapLinkTag
                      to={isViewRequestUrlFunction ? viewRequestUrl(data.datatable.data[key]["clientRequestId"]) : undefined}
                      renderLink={isViewRequestUrlFunction}
                    >
                      <span
                        onClick={
                          isDraftSection
                            ? draftClick
                            : viewRequestNew
                        }
                        style={{ cursor: "pointer" }}
                        id={data.datatable.data[key]["clientRequestId"]}
                        className="request-id"
                      >
                        Request #{data.datatable.data[key]["requestId"]}
                      </span>
                    </WrapLinkTag>
                    <span
                      style={{
                        color: "#DFDFDF",
                        marginLeft: "14px",
                        marginRight: "14px",
                      }}
                    >
                      {"|"}
                    </span>
                    <span
                      style={{
                        display: user.group_id == 3 ? "" : "none",
                        color: "#f36a10",
                      }}
                      className="request-id"
                    >
                      {data.datatable.data[key]["company_name"] &&
                        data.datatable.data[key]["company_name"]
                        ? data.datatable.data[key]["company_name"]
                        : ""}
                    </span>
                    <div className="request-right-section">
                      <span
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="rating"
                      >
                        {renderRatingStar(
                          data.datatable.data[key]["requestRating"]
                        )}
                      </span>
                      <span
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="time-spent"
                      >
                        Time Spent: {data.datatable.data[key]["timeSpent"]}
                      </span>
                      <span className="due-date">
                        Due: {convertUTCToLocale(data.datatable.data[key]["requestDueDate"])?.date}
                      </span>
                      <span className="last-updated">
                        Last Updated:{" "}
                        {convertUTCToLocale(data.datatable.data[key]["requestLastUpdateDate"])?.dateAndTime}
                      </span>
                      {(isDraftSection || data.datatable.data[key]["requestStatus"] ===
                        "Queued" ||
                        data.datatable.data[key]["status"] === "0") && (
                          <span
                            style={{
                              display:
                                hideDeleteIcon ||
                                  (!data.datatable.data[key]["is_delete"]) && !isDraftSection
                                  ? "none"
                                  : "",
                            }}
                            className="request-delete"
                            onClick={deleteRequestFromList}
                            id={data.datatable.data[key]["clientRequestId"]}
                          >
                            request-delete
                          </span>
                        )}
                      <span className="request-no">#{index + 1}</span>
                      <span
                        id={data.datatable.data[key]["clientRequestId"]}
                        onClick={copyRequest}
                        style={{
                          display: ratingCopyTimeSpentFlag ? "" : "none",
                        }}
                        className="copy-tool"
                      >
                        cursor-box
                      </span>
                    </div>
                  </div>
                  <WrapLinkTag
                    to={isViewRequestUrlFunction ? viewRequestUrl(data.datatable.data[key]["clientRequestId"]) : undefined}
                    renderLink={isViewRequestUrlFunction}
                  >
                    {getItemContent(key)}
                  </WrapLinkTag>
                </li>
              );
            })
          }
        </ul>
        <div className="moredesignrequests">
          {data &&
            data.datatable &&
            data.datatable.data.length !== 0 &&
            !(
              data.datatable.data.length === 0 ||
              data.datatable.data.length === data.datatable.recordsTotal ||
              data.datatable.data.length === data.datatable?.recordsFiltered
            ) && (
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={loadMoreRecords}
                href={void 0}
                title="And 20 more design requests..."
              >
                And 20 more design requests...
              </a>
            )}
        </div>
      </div>
      {(data === "" || data?.datatable?.data.length === 0) && <MyRequestAddRequest accountType={user.group_id} />}
    </div>
  );
}

const WrapLinkTag = memo(({ children, to, renderLink }) => (
  renderLink ? (
    <Link to={to}>
      {children}
    </Link>
  ) : children
));

export default RequestListItem;
