/* eslint-disable eqeqeq */
import axios from 'axios';
import apiUrl,{getApiUrl,reportUrls} from '../config/urls'

const headers = {
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Accept: "application/json",
};

const headersWithToken = (clientAccessToken) => ({
  "Content-Type": "application/x-www-form-urlencoded",
  Platform: "spa",
  Authorization: `Bearer ${clientAccessToken}`,
  Accept: "application/json",
});

const headersWithTokenJson = (clientAccessToken) => ({
  "Content-Type": "application/json",
  Platform: "spa",
  Authorization: `Bearer ${clientAccessToken}`,
  Accept: "application/json",
});

const headersWithTokenFormData = (clientAccessToken) => ({
  Platform: "spa",
  Authorization: `Bearer ${clientAccessToken}`,
  Accept: "application/json",  
});

export const postApi = (url, body) => {
  const requestOptions = {
    method: "POST",
    headers: headers,
    body: body,
  };

  return fetch(url, requestOptions);
};

export const postApiToken = (url, clientAccessToken, body) => {
  const requestOptions = {
    method: "POST",
    headers: headersWithToken(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const postApiTokenJson = (url, clientAccessToken, body) => {
  const requestOptions = {
    method: "POST",
    headers: headersWithTokenJson(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const postApiTokenFormData = (url, clientAccessToken, body) => {
  const requestOptions = {
    method: "POST",
    headers: headersWithTokenFormData(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const getApi = (url, params) => {
  const requestOptions = {
    method: "GET",
    headers: headers,
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}?${params ? params : ""}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const getApiToken = (url, params, clientAccessToken) => {
  const requestOptions = {
    method: "GET",
    headers: headersWithToken(clientAccessToken),
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}?${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const getApiTokenSlash = (url, params, clientAccessToken) => {
  const requestOptions = {
    method: "GET",
    headers: headersWithToken(clientAccessToken),
  };

  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}/${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const putApiToken = (url, body, clientAccessToken) => {
  const requestOptions = {
    method: "PUT",
    headers: headersWithToken(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const putApiTokenJson = (url, body, clientAccessToken) => {
  const requestOptions = {
    method: "PUT",
    headers: headersWithTokenJson(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const deleteApiToken = (url, clientAccessToken, body) => {
  const requestOptions = {
    method: "DELETE",
    headers: headersWithToken(clientAccessToken),
    body: body,
  };

  return fetch(url, requestOptions);
};

export const deleteApiTokenSlash  = (url, clientAccessToken, params) => {
  const requestOptions = {
    method: "DELETE",
    headers: headersWithToken(clientAccessToken),
  };
  let requestUrl = url;

  if (params !== null) {
    requestUrl = `${url}/${params}`;
  }

  return fetch(requestUrl, requestOptions);
};

export const fetchDoorDetails = (url,doorId,clientAccessToken)=>{
  const requestOptions = {
    method: "GET",
    headers: headersWithToken(clientAccessToken),
  };

  let finalUrl = `${url}${doorId}`;

  return fetch(finalUrl, requestOptions);
}

export const getBase64DataFromUrl = (url,clientAccessToken)=>{
  let file = new FormData()
  file.set('url[0]',url)
  return axios.post(
    apiUrl('CLIENT_GET_BASE64_URL'),
    file,
    {
      headers: {        
        Platform: "spa",
        Authorization: `Bearer ${clientAccessToken}`,
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
    }
  );
}

export const uploadCommentMedia = (media,requestId,clientAccessToken)=>{
let commentMedia = new FormData()
commentMedia.set("request_id", requestId)
commentMedia.set("file",media)
return axios.post(
  apiUrl('UPLOAD_COMMENT_MEDIA'),
  commentMedia,
  {
    headers: {        
      Platform: "spa",
      Authorization: `Bearer ${clientAccessToken}`,
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
    },
  }
);
}

export const updateUserDetails = (email,fullName,clientAccessToken,userPhoneNumber='',userCompany='') => {
  let userDetails = new URLSearchParams();
  userDetails.set("name",fullName)
  userDetails.set("email",email)
  userDetails.set("phone",userPhoneNumber)
  userDetails.set("company_name",userCompany)
  return axios.post(apiUrl("UPDATE_USER_PROFILE_INFORMATION"),userDetails,{
    headers:{
      Platform: "spa",
      Authorization: `Bearer ${clientAccessToken}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  })
}

export const resetUserPassword = (email,clientAccessToken)=>{

  let userDetails = new URLSearchParams();  
  userDetails.set("email",email)
  return axios.post(apiUrl("FORGOT_PASSWORD_URL"),userDetails,{
    headers:{
      Platform: "spa",
      Authorization: `Bearer ${clientAccessToken}`,
      Accept: "application/json",
      "Content-Type": "application/x-www-form-urlencoded",
    }
  })
}

export const getClientReportDetails = (clientAccessToken, group_id = 1, client_id = 0)=>{
  if(group_id === 1 || group_id === 2) {
    return axios.get(apiUrl("CLIENT_REPORT_DETAILS"),{
      headers:{
        Platform: "spa",
        Authorization: `Bearer ${clientAccessToken}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      }
    })
  } else {
    return axios.get(getApiUrl(reportUrls.DESIGNER_REPORT_DETAILS,`?client_id=${client_id}`),{
      headers: headersWithTokenJson(clientAccessToken)
    })
  }
}

export const getClientProductivityRatio = (clientAccessToken,month=1) => axios
.get(getApiUrl(reportUrls.GET_CLIENT_PRODUCTIVITY_RATIO,`?selected_month=${month}`),{
  headers: headersWithTokenJson(clientAccessToken)
})

export const getDesignerProductivityRatio = (clientAccessToken,designerId,month=1) => axios
.get(getApiUrl(reportUrls.GET_DESIGNER_PRODUCTIVITY_RATIO,`?client_id=${designerId}&selected_month=${month}`),{
  headers: headersWithTokenJson(clientAccessToken)
})

export const getClientTotalRequestSubmitted = (clientAccessToken,start,length,
  title,status,createdBy, approvedBy, createdFrom,createdTo,
        approvedFrom,approvedTo, sortParams) => axios
.get(getApiUrl(reportUrls.GET_CLIENT_TOTAL_REQUEST_SUBMITTED,`?start=${start}&length=${length}&
title=${title}&request_status=${status == 0 ? '' : status}&created_by=${createdBy == 0 ? '' : createdBy}&approved_by=${approvedBy == 0 ? '' : approvedBy}&
created_from=${createdFrom}&created_to=${createdTo}&approved_from=${approvedFrom}&approved_to=${approvedTo}${sortParams}`),{
  headers: headersWithTokenJson(clientAccessToken)
})

export const getDesignerTotalRequestSubmitted = (clientAccessToken,clientId,start,length,title,status,createdBy, approvedBy, createdFrom,createdTo,
  approvedFrom,approvedTo,sortParams) => axios
.get(getApiUrl(reportUrls.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED,
  `?client_id=${clientId}&start=${start}&length=${length}&
  title=${title}&request_status=${status ==0 ? '' : status}&created_by=${createdBy == 0 ? '' : createdBy}&approved_by=${approvedBy == 0 ? '' : approvedBy}&
  created_from=${createdFrom}&created_to=${createdTo}&approved_from=${approvedFrom}&approved_to=${approvedTo}${sortParams}`
  ),{
  headers:headersWithTokenJson(clientAccessToken)
})

export const exportToExcelAPI = (clientAccessToken,start,length,title,status,createdBy, approvedBy, createdFrom,createdTo,
  approvedFrom,approvedTo,sortParams,download)=>{
  return axios
  .get(getApiUrl(reportUrls.GET_CLIENT_TOTAL_REQUEST_SUBMITTED,
    `?start=${start}&length=${length}&
    title=${title}&request_status=${status ==0 ? '' : status}&created_by=${createdBy == 0 ? '' : createdBy}&approved_by=${approvedBy == 0 ? '' : approvedBy}&
    created_from=${createdFrom}&created_to=${createdTo}&approved_from=${approvedFrom}&approved_to=${approvedTo}&download=${download}&sortParams=${sortParams}`),{
      headers:{
        Platform: "spa",
        Authorization: `Bearer ${clientAccessToken}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }
  );
}

export const exportToExcelAPIForDesigner = (clientAccessToken,clientId,start,length,title,status,createdBy, approvedBy, createdFrom,createdTo,
  approvedFrom,approvedTo,download,sortParams)=>{
  return axios
  .get(getApiUrl(reportUrls.GET_DESIGNER_TOTAL_REQUEST_SUBMITTED,
    `?client_id=${clientId}&start=${start}&length=${length}&title=${title}&request_status=${status ==0 ? '' : status}&created_by=${createdBy == 0 ? '' : createdBy}&approved_by=${approvedBy == 0 ? '' : approvedBy}&created_from=${createdFrom}&created_to=${createdTo}&approved_from=${approvedFrom}&approved_to=${approvedTo}&download=${download}&sortParams=${sortParams}`),{
      headers:{
        Platform: "spa",
        Authorization: `Bearer ${clientAccessToken}`,
        Accept: "application/json",
        "Content-Type": "application/x-www-form-urlencoded",
      }
    }
  );
}