import React from 'react';
import {BrowserRouter as Router,Switch,Route,Redirect} from "react-router-dom";
import { useCookies } from "react-cookie";
import 'react-quill/dist/quill.snow.css';
import Login from "./pages/Login";
// import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Registeration from "./pages/Registeration";
import Main from "./pages/Main";
import ToastManager from "./components/ToastManager";
import Home from "./pages/Home";

function App() {
    return (
      <>
        <ToastManager />
        <Router>
          <Switch>
          <Route path="/">
              <Home />
            </Route>
            {/* <RedirectRoute exact path="/" /> */}
            {/* <PublicRoute path="/" component={Home} /> */}
            {/* <PublicRoute path="/login" component={Login} /> */}
            {/* <Route path="/signup">
              <Signup />
            </Route> */}
            {/* <Route path="/forgotpassword">
              <ForgetPassword />
            </Route>
            <Route path="/auth/reset-password/:token">
              <ResetPassword />
            </Route>
            <Route path="/invite/:token">
              <Registeration />
            </Route>
            <PrivateRoute path="/app" component={Main} /> */}
          </Switch>
        </Router>
      </>
  );
}

function RedirectRoute({ children, ...rest }) {
  const [cookies] = useCookies(["clientAccessToken"]);
  return (
    <Route
      {...rest}
      render={({ location }) => (
        <Redirect
          to={{
            pathname: cookies.clientAccessToken ? "/app" : "/login",
            state: { from: location },
          }}
        />
      )}
    />
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies(["clientAccessToken"]);
  const isLoggedIn = cookies.clientAccessToken ? true : false;

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  const [cookies] = useCookies(["clientAccessToken"]);
  const isLoggedIn = cookies.clientAccessToken ? true : false;
  let redirect = false;
  if(window.location.hostname == "www.kitchendesign365.com"){
    redirect = false;
  }else{
    redirect = true;
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        !isLoggedIn && !redirect ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default App;
