import * as Yup from "yup";

export const formFields = {
    first_name: {
        key: "first_name",
        type: "text",
        name: "first_name",
        className: "form-control",
        placeholder: "Enter your First name",
        label: "First Name",
        htmlFor: "first_name",
        required: true,
    },
    last_name: {
        key: "last_name",
        type: "text",
        className: "form-control",
        name: "last_name",
        placeholder: "Enter your Last name",
        label: "Last Name",
        htmlFor: "last_name",
        required: true,
    },
    email: {
        key: "email",
        type: "text",
        className: "form-control",
        name: "email",
        placeholder: "Enter your Email address",
        label: "Email",
        htmlFor: "email",
        required: true,
    },
    phone: {
        key: "phone",
        type: "text",
        className: "form-control",
        name: "phone",
        placeholder: "Enter a valid 10 digit number",
        label: "Phone",
        htmlFor: "phone",
        required: true,
        disableDropdown: true,
        maxlength: "10"
    },
    company_name: {
        key: "company_name",
        type: "text",
        className: "form-control",
        name: "company_name",
        placeholder: "Enter your Company name",
        label: "Company Name",
        htmlFor: "company_name",
        required: true,
    },
    website_url: {
        key: "website_url",
        type: "text",
        className: "form-control",
        name: "website_url",
        placeholder: "Enter your Website URL",
        label: "Website URL",
        htmlFor: "website_url",
    },
    message: {
        key: "message",
        type: "textarea",
        className: "form-control",
        name: "message",
        placeholder: "Enter your message",
        label: "Message",
        htmlFor: "message",
    },

}

export const validationSchemaSignupForm = Yup.object().shape({
    first_name: Yup.string().required('First Name is Required.'),
    last_name: Yup.string().required("Last Name is Required."),
    email: Yup.string().required('Email is Required.').email("Enter valid email"),
    phone: Yup.string().required('Phone is Required.').matches(/^\d+$/, "The field should have digits only.").min(10, "Please enter valid Phone."),
    company_name: Yup.string().required('Company Name is Required.'),
    website_url: Yup.string().matches(
        /[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
        // /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
        "The website url format is invalid."
    ),
});