import React, { memo } from 'react'

 function DesignRequests() {
    return (
        <div className='right-info-block account-info-contact'>
                <div className='inner-info-block'>
                    <div className='info-title'>
                        <h4>2020 Design Requests</h4>
                    </div>
                    <ul className='contact-info'>
                        <li><span>No. of Requests: <i>( 0 )</i></span></li>
                        <li><span>You have not created any design Request yet.</span></li>
                    </ul>
                    {/* <a href='#' className='common-button'>CREATE REQUEST</a> */}
                </div>
            </div>
    )
}
export default memo(DesignRequests)