import React, { memo } from 'react'
import PropTypes from 'prop-types';
import Label from '../Label'
import { Error } from '../../CustomComponents'
import { getFieldCSSClasses } from '../../../../Utils/utils';

/**
 * This is  Input function Component. 
 * @param {Object} setting  Setting  Pass Object Value.
 * @param {String} value Value Pass String Value.
 * @param {Function} onChange OnChange Pass Function Value.
 * @param {Function} onBlur OnBlur Pass Function Value.
 * @param {Boolean} touched Touched Pass Boolean Value.
 * @param {String} errors Errors Pass String Value.
 * @returns  Input HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 10 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

const Input = ({ setting: { key, type, name, placeholder, label, htmlFor, required, className, maxlength },
    value, onChange, onBlur, touched, errors
}) => (
    <>
        {label && <Label label={label} htmlFor={htmlFor} required={required} />}
        <input
            id={key}
            type={type}
            name={name}
            maxLength={maxlength}
            placeholder={placeholder}
            className={`${className ? className : ""} ${getFieldCSSClasses({ touched, errors })}`}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
        {touched && <Error label={label} touched={touched} errors={errors} />}
    </>
);

Input.propTypes = {
    setting: PropTypes.shape({
        key: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        htmlFor: PropTypes.string,
        required: PropTypes.bool,
        maxlength:PropTypes.string,
    }),
    touched: PropTypes.bool,
    errors: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

Input.defaultProps = {
    setting: {
        key: "",
        type: "",
        name: "",
        placeholder: "",
        className: "",
        label: "",
        maxlength:"",
        htmlFor: "",
        required: false,
    },
    touched: false,
    errors: "",
    value: "",
    onChange: () => { },
    onBlur: () => { },
}

export default memo(Input)