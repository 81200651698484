import { put, takeLatest, call, all, select } from "redux-saga/effects";
import queryString from "query-string";
import ShortUniqueId from "short-unique-id";
import { postApiToken, putApiToken, getApiTokenSlash, getApiToken, deleteApiTokenSlash } from "../apis";
import apiUrl from "../../config/urls";
import { actions as generalActions } from "./GeneralReducer";

const uid = new ShortUniqueId();

export const actionTypes = {
    // account information start
    CHANGE_PASSWORD: "CHANGE_PASSWORD", // seaction change password start
    CHANGE_PASSWORD_LOADIING: "CHANGE_PASSWORD_LOADIING",
    CHANGE_PASSWORD_SUCESSS: "CHANGE_PASSWORD_SUCESSS",
    CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
    CLEAR_CHANGE_PASSWORD_FLAGS: "CLEAR_CHANGE_PASSWORD_FLAGS",// seaction change password end
    ACCOUNT_INFO_UPDATE: "ACCOUNT_INFO_UPDATE",
    ACCOUNT_INFO_UPDATE_LOADIING: "ACCOUNT_INFO_UPDATE_LOADIING",
    ACCOUNT_INFO_UPDATE_SUCESSS: "ACCOUNT_INFO_UPDATE_SUCESSS",
    ACCOUNT_INFO_UPDATE_FAILED: "ACCOUNT_INFO_UPDATE_FAILED",
    CLEAR_ACCOUNT_INFO_UPDATE_FLAGS: "CLEAR_ACCOUNT_INFO_UPDATE_FLAGS",
    GET_ACCOUNT_INFO: "GET_ACCOUNT_INFO",
    GET_ACCOUNT_INFO_LOADIING: "GET_ACCOUNT_INFO_LOADIING",
    GET_ACCOUNT_INFO_SUCESSS: "GET_ACCOUNT_INFO_SUCESSS",
    GET_ACCOUNT_INFO_FAILED: "GET_ACCOUNT_INFO_FAILED",
    CLEAR_GET_ACCOUNT_INFO_FLAGS: "CLEAR_GET_ACCOUNT_INFO_FLAGS", // account information end
    ADD_CLIENT_ADDRESS: "ADD_CLIENT_ADDRESS",// address book start
    ADD_CLIENT_ADDRESS_LOADIING: "ADD_CLIENT_ADDRESS_LOADIING",
    ADD_CLIENT_ADDRESS_SUCESSS: "ADD_CLIENT_ADDRESS_SUCESSS",
    ADD_CLIENT_ADDRESS_FAILED: "ADD_CLIENT_ADDRESS_FAILED",
    CLEAR_ADD_CLIENT_ADDRESS_FLAGS: "CLEAR_ADD_CLIENT_ADDRESS_FLAGS",
    GET_CLIENT_ADDRESS: "GET_CLIENT_ADDRESS",
    GET_CLIENT_ADDRESS_LOADIING: "GET_CLIENT_ADDRESS_LOADIING",
    GET_CLIENT_ADDRESS_SUCESSS: "GET_CLIENT_ADDRESS_SUCESSS",
    GET_CLIENT_ADDRESS_FAILED: "GET_CLIENT_ADDRESS_FAILED",
    CLEAR_GET_CLIENT_ADDRESS_FLAGS: "CLEAR_GET_CLIENT_ADDRESS_FLAGS",
    DELETE_CLIENT_ADDRESS: "DELETE_CLIENT_ADDRESS",
    DELETE_CLIENT_ADDRESS_LOADIING: "DELETE_CLIENT_ADDRESS_LOADIING",
    DELETE_CLIENT_ADDRESS_SUCESSS: "DELETE_CLIENT_ADDRESS_SUCESSS",
    DELETE_CLIENT_ADDRESS_FAILED: "DELETE_CLIENT_ADDRESS_FAILED",
    CLEAR_DELETE_CLIENT_ADDRESS_FLAGS: "CLEAR_DELETE_CLIENT_ADDRESS_FLAGS",
    GET_COUNTRY_LIST: "GET_COUNTRY_LIST",
    GET_COUNTRY_LIST_LOADIING: "GET_COUNTRY_LIST_LOADIING",
    GET_COUNTRY_LIST_SUCESSS: "GET_COUNTRY_LIST_SUCESSS",
    GET_COUNTRY_LIST_FAILED: "GET_COUNTRY_LIST_FAILED",
    CLEAR_GET_COUNTRY_LIST_FLAGS: "CLEAR_GET_COUNTRY_LIST_FLAGS",
    EDIT_CLIENT_ADDRESS: "EDIT_CLIENT_ADDRESS",
    EDIT_CLIENT_ADDRESS_LOADIING: "EDIT_CLIENT_ADDRESS_LOADIING",
    EDIT_CLIENT_ADDRESS_SUCESSS: "EDIT_CLIENT_ADDRESS_SUCESSS",
    EDIT_CLIENT_ADDRESS_FAILED: "EDIT_CLIENT_ADDRESS_FAILED",
    CLEAR_EDIT_CLIENT_ADDRESS_FLAGS: "CLEAR_EDIT_CLIENT_ADDRESS_FLAGS",
    UPDATE_CLIENT_ADDRESS: "UPDATE_CLIENT_ADDRESS",
    UPDATE_CLIENT_ADDRESS_LOADIING: "UPDATE_CLIENT_ADDRESS_LOADIING",
    UPDATE_CLIENT_ADDRESS_SUCESSS: "UPDATE_CLIENT_ADDRESS_SUCESSS",
    UPDATE_CLIENT_ADDRESS_FAILED: "UPDATE_CLIENT_ADDRESS_FAILED",
    CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS: "CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS",
    SET_DEFAULT_ADDRESS: "SET_DEFAULT_ADDRESS",
    SET_DEFAULT_ADDRESS_LOADIING: "SET_DEFAULT_ADDRESS_LOADIING",
    SET_DEFAULT_ADDRESS_SUCESSS: "SET_DEFAULT_ADDRESS_SUCESSS",
    SET_DEFAULT_ADDRESS_FAILED: "SET_DEFAULT_ADDRESS_FAILED",
    CLEAR_SET_DEFAULT_ADDRESS_FLAGS: "CLEAR_SET_DEFAULT_ADDRESS_FLAGS", // address book end

    SAVED_CARDS: "SAVED_CARDS",
    SAVED_CARDS_LOADIING: "SAVED_CARDS_LOADIING",
    SAVED_CARDS_SUCESSS: "SAVED_CARDS_SUCESSS",
    SAVED_CARDS_FAILED: "SAVED_CARDS_FAILED",
    CLEAR_SAVED_CARDS_FLAGS: "CLEAR_SAVED_CARDS_FLAGS",
};

const initialState = {
    // account information start
    setErrorMsgForChangePassword: "",// seaction change password start
    changePasswordResponse: '',
    changePasswordLoading: false,
    setSuccessForChangePassword: false,
    changePasswordFailed: false,// seaction change password end
    setErrorMsgForAccountInfoUpdate: "",
    accountInfoUpdateResponse: '',
    accountInfoUpdateLoading: false,
    setSuccessForAccountInfoUpdate: false,
    accountInfoUpdateFailed: false,
    setErrorMsgForGetAccountInfo: "",
    getAccountInfoResponse: '',
    getAccountInfoLoading: false,
    setSuccessForGetAccountInfo: false,
    getAccountInfoFailed: false, // account information end
    setErrorMsgForAddClientAddress: "", // address book start
    addClientAddressResponse: '',
    addClientAddressLoading: false,
    setSuccessForAddClientAddress: false,
    addClientAddressFailed: false,
    setErrorMsgForGetClientAddress: "",
    getClientAddressResponse: '',
    getClientAddressLoading: false,
    setSuccessForGetClientAddress: false,
    getClientAddressFailed: false,
    setErrorMsgForDeleteClientAddress: "",
    deleteClientAddressResponse: '',
    deleteClientAddressLoading: false,
    setSuccessForDeleteClientAddress: false,
    deleteClientAddressFailed: false,
    setErrorMsgForGetCountryList: "",
    getCountryListResponse: '',
    getCountryListLoading: false,
    setSuccessForGetCountryList: false,
    getCountryListFailed: false,
    setErrorMsgForEditClientAddress: "",
    editClientAddressResponse: '',
    editClientAddressLoading: false,
    setSuccessForEditClientAddress: false,
    editClientAddressFailed: false,
    setErrorMsgForUpdateClientAddress: "",
    updateClientAddressResponse: '',
    updateClientAddressLoading: false,
    setSuccessForUpdateClientAddress: false,
    updateClientAddressFailed: false,
    setErrorMsgForSetDefaultAddress: "",
    setDefaultAddressResponse: '',
    setDefaultAddressLoading: false,
    setSuccessForSetDefaultAddress: false,
    setDefaultAddressFailed: false,// address book end

    setErrorMsgForSavedCards: "",
    savedCardsResponse: '',
    savedCardsLoading: false,
    setSuccessForSavedCards: false,
    savedCardsFailed: false,
};
export const reducer = (state = initialState, action) => {
    switch (action.type) { // account information start
        case actionTypes.CHANGE_PASSWORD_SUCESSS: {// seaction change password start
            const { flag, changePasswordResponse } = action.payload;
            return {
                ...state,
                setSuccessForChangePassword: flag,
                changePasswordResponse: changePasswordResponse,
            };
        }
        case actionTypes.CHANGE_PASSWORD_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                changePasswordLoading: flag,
            };
        }
        case actionTypes.CHANGE_PASSWORD_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                changePasswordFailed: flag,
                setErrorMsgForChangePassword: errorMessage,
            };
        }
        case actionTypes.CLEAR_CHANGE_PASSWORD_FLAGS: {// seaction change password end
            return {
                ...state,
                changePasswordLoading: false,
                setSuccessForChangePassword: false,
                changePasswordFailed: false,
                setErrorMsgForChangePassword: "",
                changePasswordResponse: '',
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_SUCESSS: {
            const { flag, accountInfoUpdateResponse } = action.payload;
            return {
                ...state,
                setSuccessForAccountInfoUpdate: flag,
                accountInfoUpdateResponse: accountInfoUpdateResponse,
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                accountInfoUpdateLoading: flag,
            };
        }
        case actionTypes.ACCOUNT_INFO_UPDATE_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                accountInfoUpdateFailed: flag,
                setErrorMsgForAccountInfoUpdate: errorMessage,
            };
        }
        case actionTypes.CLEAR_ACCOUNT_INFO_UPDATE_FLAGS: {
            return {
                ...state,
                accountInfoUpdateLoading: false,
                setSuccessForAccountInfoUpdate: false,
                accountInfoUpdateFailed: false,
                setErrorMsgForAccountInfoUpdate: "",
                accountInfoUpdateResponse: '',
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_SUCESSS: {
            const { flag, getAccountInfoResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetAccountInfo: flag,
                getAccountInfoResponse: getAccountInfoResponse,
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getAccountInfoLoading: flag,
            };
        }
        case actionTypes.GET_ACCOUNT_INFO_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getAccountInfoFailed: flag,
                setErrorMsgForGetAccountInfo: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_ACCOUNT_INFO_FLAGS: {
            return {
                ...state,
                getAccountInfoLoading: false,
                setSuccessForGetAccountInfo: false,
                getAccountInfoFailed: false,
                setErrorMsgForGetAccountInfo: "",
                getAccountInfoResponse: '',
            };
        } // account information end
        // address book start
        case actionTypes.ADD_CLIENT_ADDRESS_SUCESSS: {
            const { flag, addClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForAddClientAddress: flag,
                addClientAddressResponse: addClientAddressResponse,
            };
        }
        case actionTypes.ADD_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                addClientAddressLoading: flag,
            };
        }
        case actionTypes.ADD_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                addClientAddressFailed: flag,
                setErrorMsgForAddClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_ADD_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                addClientAddressLoading: false,
                setSuccessForAddClientAddress: false,
                addClientAddressFailed: false,
                setErrorMsgForAddClientAddress: "",
                addClientAddressResponse: '',
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_SUCESSS: {
            const { flag, getClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetClientAddress: flag,
                getClientAddressResponse: getClientAddressResponse,
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getClientAddressLoading: flag,
            };
        }
        case actionTypes.GET_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getClientAddressFailed: flag,
                setErrorMsgForGetClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                getClientAddressLoading: false,
                setSuccessForGetClientAddress: false,
                getClientAddressFailed: false,
                setErrorMsgForGetClientAddress: "",
                getClientAddressResponse: '',
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_SUCESSS: {
            const { flag, deleteClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForDeleteClientAddress: flag,
                deleteClientAddressResponse: deleteClientAddressResponse,
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                deleteClientAddressLoading: flag,
            };
        }
        case actionTypes.DELETE_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                deleteClientAddressFailed: flag,
                setErrorMsgForDeleteClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_DELETE_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                deleteClientAddressLoading: false,
                setSuccessForDeleteClientAddress: false,
                deleteClientAddressFailed: false,
                setErrorMsgForDeleteClientAddress: "",
                deleteClientAddressResponse: '',
            };
        }
        case actionTypes.GET_COUNTRY_LIST_SUCESSS: {
            const { flag, getCountryListResponse } = action.payload;
            return {
                ...state,
                setSuccessForGetCountryList: flag,
                getCountryListResponse: getCountryListResponse,
            };
        }
        case actionTypes.GET_COUNTRY_LIST_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                getCountryListLoading: flag,
            };
        }
        case actionTypes.GET_COUNTRY_LIST_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                getCountryListFailed: flag,
                setErrorMsgForGetCountryList: errorMessage,
            };
        }
        case actionTypes.CLEAR_GET_COUNTRY_LIST_FLAGS: {
            return {
                ...state,
                getCountryListLoading: false,
                setSuccessForGetCountryList: false,
                getCountryListFailed: false,
                setErrorMsgForGetCountryList: "",
                getCountryListResponse: '',
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_SUCESSS: {
            const { flag, editClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForEditClientAddress: flag,
                editClientAddressResponse: editClientAddressResponse,
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                editClientAddressLoading: flag,
            };
        }
        case actionTypes.EDIT_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                editClientAddressFailed: flag,
                setErrorMsgForEditClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_EDIT_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                editClientAddressLoading: false,
                setSuccessForEditClientAddress: false,
                editClientAddressFailed: false,
                setErrorMsgForEditClientAddress: "",
                editClientAddressResponse: '',
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_SUCESSS: {
            const { flag, updateClientAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForUpdateClientAddress: flag,
                updateClientAddressResponse: updateClientAddressResponse,
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                updateClientAddressLoading: flag,
            };
        }
        case actionTypes.UPDATE_CLIENT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                updateClientAddressFailed: flag,
                setErrorMsgForUpdateClientAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS: {
            return {
                ...state,
                updateClientAddressLoading: false,
                setSuccessForUpdateClientAddress: false,
                updateClientAddressFailed: false,
                setErrorMsgForUpdateClientAddress: "",
                updateClientAddressResponse: '',
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_SUCESSS: {
            const { flag, setDefaultAddressResponse } = action.payload;
            return {
                ...state,
                setSuccessForSetDefaultAddress: flag,
                setDefaultAddressResponse: setDefaultAddressResponse,
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                setDefaultAddressLoading: flag,
            };
        }
        case actionTypes.SET_DEFAULT_ADDRESS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                setDefaultAddressFailed: flag,
                setErrorMsgForSetDefaultAddress: errorMessage,
            };
        }
        case actionTypes.CLEAR_SET_DEFAULT_ADDRESS_FLAGS: {
            return {
                ...state,
                setDefaultAddressLoading: false,
                setSuccessForSetDefaultAddress: false,
                setDefaultAddressFailed: false,
                setErrorMsgForSetDefaultAddress: "",
                setDefaultAddressResponse: '',
            };
        }// address book end



        case actionTypes.SAVED_CARDS_SUCESSS: {
            const { flag, savedCardsResponse } = action.payload;
            return {
                ...state,
                setSuccessForSavedCards: flag,
                savedCardsResponse: savedCardsResponse,
            };
        }
        case actionTypes.SAVED_CARDS_LOADIING: {
            const { flag } = action.payload;
            return {
                ...state,
                savedCardsLoading: flag,
            };
        }
        case actionTypes.SAVED_CARDS_FAILED: {
            const { flag, errorMessage } = action.payload;
            return {
                ...state,
                savedCardsFailed: flag,
                setErrorMsgForSavedCards: errorMessage,
            };
        }
        case actionTypes.CLEAR_SAVED_CARDS_FLAGS: {
            return {
                ...state,
                savedCardsLoading: false,
                setSuccessForSavedCards: false,
                savedCardsFailed: false,
                setErrorMsgForSavedCards: "",
                savedCardsResponse: '',
            };
        }

        default: {
            return state;
        }
    }
};

export const actions = { // account information start
    changePassword: (payload) => ({// seaction change password start
        type: actionTypes.CHANGE_PASSWORD,
        payload,
    }),
    setChangePasswordLoading: (flag) => ({
        type: actionTypes.CHANGE_PASSWORD_LOADIING,
        payload: { flag },
    }),
    setChangePasswordSuccess: (flag, changePasswordResponse = "") => ({
        type: actionTypes.CHANGE_PASSWORD_SUCESSS,
        payload: { flag, changePasswordResponse },
    }),
    setChangePasswordFailed: (flag, errorMessage = "") => ({
        type: actionTypes.CHANGE_PASSWORD_FAILED,
        payload: { flag, errorMessage },
    }),
    clearChangePasswordFlags: () => ({
        type: actionTypes.CLEAR_CHANGE_PASSWORD_FLAGS,
    }),// seaction change password end
    accountInfoUpdate: (payload) => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE,
        payload,
    }),
    setAccountInfoUpdateLoading: (flag) => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_LOADIING,
        payload: { flag },
    }),
    setAccountInfoUpdateSuccess: (flag, accountInfoUpdateResponse = "") => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_SUCESSS,
        payload: { flag, accountInfoUpdateResponse },
    }),
    setAccountInfoUpdateFailed: (flag, errorMessage = "") => ({
        type: actionTypes.ACCOUNT_INFO_UPDATE_FAILED,
        payload: { flag, errorMessage },
    }),
    clearAccountInfoUpdateFlags: () => ({
        type: actionTypes.CLEAR_ACCOUNT_INFO_UPDATE_FLAGS,
    }),
    getAccountInfo: (payload) => ({
        type: actionTypes.GET_ACCOUNT_INFO,
        payload,
    }),
    setGetAccountInfoLoading: (flag) => ({
        type: actionTypes.GET_ACCOUNT_INFO_LOADIING,
        payload: { flag },
    }),
    setGetAccountInfoSuccess: (flag, getAccountInfoResponse = "") => ({
        type: actionTypes.GET_ACCOUNT_INFO_SUCESSS,
        payload: { flag, getAccountInfoResponse },
    }),
    setGetAccountInfoFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_ACCOUNT_INFO_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetAccountInfoFlags: () => ({
        type: actionTypes.CLEAR_GET_ACCOUNT_INFO_FLAGS,
    }), // account information end
    // address book start
    addClientAddress: (payload) => ({
        type: actionTypes.ADD_CLIENT_ADDRESS,
        payload,
    }),
    setAddClientAddressLoading: (flag) => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setAddClientAddressSuccess: (flag, addClientAddressResponse = "") => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, addClientAddressResponse },
    }),
    setAddClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.ADD_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearAddClientAddressFlags: () => ({
        type: actionTypes.CLEAR_ADD_CLIENT_ADDRESS_FLAGS,
    }),
    getClientAddress: (payload) => ({
        type: actionTypes.GET_CLIENT_ADDRESS,
        payload,
    }),
    setGetClientAddressLoading: (flag) => ({
        type: actionTypes.GET_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setGetClientAddressSuccess: (flag, getClientAddressResponse = "") => ({
        type: actionTypes.GET_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, getClientAddressResponse },
    }),
    setGetClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetClientAddressFlags: () => ({
        type: actionTypes.CLEAR_GET_CLIENT_ADDRESS_FLAGS,
    }),
    deleteClientAddress: (payload) => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS,
        payload,
    }),
    setDeleteClientAddressLoading: (flag) => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setDeleteClientAddressSuccess: (flag, deleteClientAddressResponse = "") => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, deleteClientAddressResponse },
    }),
    setDeleteClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.DELETE_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearDeleteClientAddressFlags: () => ({
        type: actionTypes.CLEAR_DELETE_CLIENT_ADDRESS_FLAGS,
    }),
    getCountryList: (payload) => ({
        type: actionTypes.GET_COUNTRY_LIST,
        payload,
    }),
    setGetCountryListLoading: (flag) => ({
        type: actionTypes.GET_COUNTRY_LIST_LOADIING,
        payload: { flag },
    }),
    setGetCountryListSuccess: (flag, getCountryListResponse = "") => ({
        type: actionTypes.GET_COUNTRY_LIST_SUCESSS,
        payload: { flag, getCountryListResponse },
    }),
    setGetCountryListFailed: (flag, errorMessage = "") => ({
        type: actionTypes.GET_COUNTRY_LIST_FAILED,
        payload: { flag, errorMessage },
    }),
    clearGetCountryListFlags: () => ({
        type: actionTypes.CLEAR_GET_COUNTRY_LIST_FLAGS,
    }),
    editClientAddress: (payload) => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS,
        payload,
    }),
    setEditClientAddressLoading: (flag) => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setEditClientAddressSuccess: (flag, editClientAddressResponse = "") => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, editClientAddressResponse },
    }),
    setEditClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.EDIT_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearEditClientAddressFlags: () => ({
        type: actionTypes.CLEAR_EDIT_CLIENT_ADDRESS_FLAGS,
    }),
    updateClientAddress: (payload) => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS,
        payload,
    }),
    setUpdateClientAddressLoading: (flag) => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setUpdateClientAddressSuccess: (flag, updateClientAddressResponse = "") => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_SUCESSS,
        payload: { flag, updateClientAddressResponse },
    }),
    setUpdateClientAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.UPDATE_CLIENT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearUpdateClientAddressFlags: () => ({
        type: actionTypes.CLEAR_UPDATE_CLIENT_ADDRESS_FLAGS,
    }),
    setDefaultAddress: (payload) => ({
        type: actionTypes.SET_DEFAULT_ADDRESS,
        payload,
    }),
    setSetDefaultAddressLoading: (flag) => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_LOADIING,
        payload: { flag },
    }),
    setSetDefaultAddressSuccess: (flag, setDefaultAddressResponse = "") => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_SUCESSS,
        payload: { flag, setDefaultAddressResponse },
    }),
    setSetDefaultAddressFailed: (flag, errorMessage = "") => ({
        type: actionTypes.SET_DEFAULT_ADDRESS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearSetDefaultAddressFlags: () => ({
        type: actionTypes.CLEAR_SET_DEFAULT_ADDRESS_FLAGS,
    }),// address book end

    savedCards: (payload) => ({
        type: actionTypes.SAVED_CARDS,
        payload,
    }),
    setSavedCardsLoading: (flag) => ({
        type: actionTypes.SAVED_CARDS_LOADIING,
        payload: { flag },
    }),
    setSavedCardsSuccess: (flag, savedCardsResponse = "") => ({
        type: actionTypes.SAVED_CARDS_SUCESSS,
        payload: { flag, savedCardsResponse },
    }),
    setSavedCardsFailed: (flag, errorMessage = "") => ({
        type: actionTypes.SAVED_CARDS_FAILED,
        payload: { flag, errorMessage },
    }),
    clearSavedCardsFlags: () => ({
        type: actionTypes.CLEAR_SAVED_CARDS_FLAGS,
    }),
};

export function* saga() { // account information start
    yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordSaga);
    yield takeLatest(actionTypes.ACCOUNT_INFO_UPDATE, accountInfoUpdateSaga);
    yield takeLatest(actionTypes.GET_ACCOUNT_INFO, getAccountInfoSaga); // account information end
    yield takeLatest(actionTypes.ADD_CLIENT_ADDRESS, addClientAddressSaga);// address book start
    yield takeLatest(actionTypes.GET_CLIENT_ADDRESS, getClientAddressSaga);
    yield takeLatest(actionTypes.DELETE_CLIENT_ADDRESS, deleteClientAddressSaga);
    yield takeLatest(actionTypes.GET_COUNTRY_LIST, getCountryListSaga);
    yield takeLatest(actionTypes.EDIT_CLIENT_ADDRESS, editClientAddressSaga);
    yield takeLatest(actionTypes.UPDATE_CLIENT_ADDRESS, updateClientAddressSaga);
    yield takeLatest(actionTypes.SET_DEFAULT_ADDRESS, setDefaultAddressSaga);// address book end

    yield takeLatest(actionTypes.SAVED_CARDS, savedCardsSaga);

}
// account information start
function* changePasswordSaga(action) {
    yield all([
        put(actions.setChangePasswordLoading(true)),
        put(actions.setChangePasswordSuccess(false)),
        put(actions.setChangePasswordFailed(false)),
    ]);
    try {
        const { old_password, new_password, confirm_password, clientAccessToken } = action.payload
        const payload = { old_password, new_password, confirm_password };
        const response = yield call(
            postApiToken,
            apiUrl("CHANGE_PASSWORD"),
            clientAccessToken,
            queryString.stringify(payload)
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordSuccess(true, responseData)),
                    put(actions.setChangePasswordFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordSuccess(false)),
                    put(actions.setChangePasswordFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordFailed(true, responseData.message)),
                    put(actions.setChangePasswordSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setChangePasswordLoading(false)),
                    put(actions.setChangePasswordFailed(true)),
                    put(actions.setChangePasswordSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setChangePasswordLoading(false)),
            put(actions.setChangePasswordSuccess(false)),
            put(actions.setChangePasswordFailed(true)),
        ]);
    }
}

function* accountInfoUpdateSaga(action) {
    yield all([
        put(actions.setAccountInfoUpdateLoading(true)),
        put(actions.setAccountInfoUpdateSuccess(false)),
        put(actions.setAccountInfoUpdateFailed(false)),
    ]);
    try {
        const { user } = yield select((state) => state.auth);
        const { name, email, phone, company_name, phone_code, clientAccessToken } = action.payload
        const payload = { name, email, phone, company_name, phone_code };
        const response = yield call(
            postApiToken,
            apiUrl("ACCOUNT_INFO_UPDATE"),
            clientAccessToken,
            queryString.stringify(payload)
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateSuccess(true, responseData)),
                    put(actions.setAccountInfoUpdateFailed(false)),
                ]);
                yield put(actions.getAccountInfo({ client_id: user?.id, clientAccessToken }));
            } else {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                    put(actions.setAccountInfoUpdateFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateFailed(true, responseData.message)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setAccountInfoUpdateLoading(false)),
                    put(actions.setAccountInfoUpdateFailed(true)),
                    put(actions.setAccountInfoUpdateSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setAccountInfoUpdateLoading(false)),
            put(actions.setAccountInfoUpdateSuccess(false)),
            put(actions.setAccountInfoUpdateFailed(true)),
        ]);
    }
}

function* getAccountInfoSaga({ payload: { client_id, clientAccessToken } }) {
    yield all([
        put(actions.setGetAccountInfoLoading(true)),
        put(actions.setGetAccountInfoSuccess(false)),
        put(actions.setGetAccountInfoFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("GET_ACCOUNT_INFO"),
            queryString.stringify({ client_id: client_id }),
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoSuccess(true, responseData)),
                    put(actions.setGetAccountInfoFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoSuccess(false)),
                    put(actions.setGetAccountInfoFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoFailed(true, responseData.message)),
                    put(actions.setGetAccountInfoSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetAccountInfoLoading(false)),
                    put(actions.setGetAccountInfoFailed(true)),
                    put(actions.setGetAccountInfoSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Account Information",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetAccountInfoLoading(false)),
            put(actions.setGetAccountInfoSuccess(false)),
            put(actions.setGetAccountInfoFailed(true)),
        ]);
    }
}
// account information end
// address book start
function* addClientAddressSaga({ payload: { payloadObj, clientAccessToken } }) {
    yield all([
        put(actions.setAddClientAddressLoading(true)),
        put(actions.setAddClientAddressSuccess(false)),
        put(actions.setAddClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("CLIENT_ADDRESS"),
            clientAccessToken,
            queryString.stringify(payloadObj)

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressSuccess(true, responseData)),
                    put(actions.setAddClientAddressFailed(false)),
                ]);
                yield put(actions.getClientAddress({ clientAccessToken }))
            } else {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressSuccess(false)),
                    put(actions.setAddClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressFailed(true, responseData.message)),
                    put(actions.setAddClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setAddClientAddressLoading(false)),
                    put(actions.setAddClientAddressFailed(true)),
                    put(actions.setAddClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setAddClientAddressLoading(false)),
            put(actions.setAddClientAddressSuccess(false)),
            put(actions.setAddClientAddressFailed(true)),
        ]);
    }
}

function* getClientAddressSaga({ payload: { clientAccessToken } }) {
    yield all([
        put(actions.setGetClientAddressLoading(true)),
        put(actions.setGetClientAddressSuccess(false)),
        put(actions.setGetClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("CLIENT_ADDRESS"),
            null,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressSuccess(true, responseData)),
                    put(actions.setGetClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressSuccess(false)),
                    put(actions.setGetClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressFailed(true, responseData.message)),
                    put(actions.setGetClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetClientAddressLoading(false)),
                    put(actions.setGetClientAddressFailed(true)),
                    put(actions.setGetClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetClientAddressLoading(false)),
            put(actions.setGetClientAddressSuccess(false)),
            put(actions.setGetClientAddressFailed(true)),
        ]);
    }
}

function* deleteClientAddressSaga({ payload: { deleteId, clientAccessToken } }) {
    yield all([
        put(actions.setDeleteClientAddressLoading(true)),
        put(actions.setDeleteClientAddressSuccess(false)),
        put(actions.setDeleteClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            deleteApiTokenSlash,
            apiUrl("CLIENT_ADDRESS"),
            clientAccessToken,
            deleteId,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressSuccess(true, responseData)),
                    put(actions.setDeleteClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                    put(actions.setDeleteClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressFailed(true, responseData.message)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setDeleteClientAddressLoading(false)),
                    put(actions.setDeleteClientAddressFailed(true)),
                    put(actions.setDeleteClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setDeleteClientAddressLoading(false)),
            put(actions.setDeleteClientAddressSuccess(false)),
            put(actions.setDeleteClientAddressFailed(true)),
        ]);
    }
}

function* getCountryListSaga({ payload: { clientAccessToken } }) {
    yield all([
        put(actions.setGetCountryListLoading(true)),
        put(actions.setGetCountryListSuccess(false)),
        put(actions.setGetCountryListFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiToken,
            apiUrl("GET_COUNTRY_LIST"),
            null,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListSuccess(true, responseData)),
                    put(actions.setGetCountryListFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListSuccess(false)),
                    put(actions.setGetCountryListFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListFailed(true, responseData.message)),
                    put(actions.setGetCountryListSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setGetCountryListLoading(false)),
                    put(actions.setGetCountryListFailed(true)),
                    put(actions.setGetCountryListSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setGetCountryListLoading(false)),
            put(actions.setGetCountryListSuccess(false)),
            put(actions.setGetCountryListFailed(true)),
        ]);
    }
}

function* editClientAddressSaga({ payload: { editClientId, clientAccessToken } }) {
    yield all([
        put(actions.setEditClientAddressLoading(true)),
        put(actions.setEditClientAddressSuccess(false)),
        put(actions.setEditClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            getApiTokenSlash,
            apiUrl("CLIENT_ADDRESS"),
            editClientId,
            clientAccessToken
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressSuccess(true, responseData)),
                    put(actions.setEditClientAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressSuccess(false)),
                    put(actions.setEditClientAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressFailed(true, responseData.message)),
                    put(actions.setEditClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setEditClientAddressLoading(false)),
                    put(actions.setEditClientAddressFailed(true)),
                    put(actions.setEditClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setEditClientAddressLoading(false)),
            put(actions.setEditClientAddressSuccess(false)),
            put(actions.setEditClientAddressFailed(true)),
        ]);
    }
}

function* updateClientAddressSaga({ payload: { payloadObj, id, clientAccessToken } }) {
    yield all([
        put(actions.setUpdateClientAddressLoading(true)),
        put(actions.setUpdateClientAddressSuccess(false)),
        put(actions.setUpdateClientAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            putApiToken,
            apiUrl("CLIENT_ADDRESS") + "/" + id,
            queryString.stringify(payloadObj),
            clientAccessToken,
        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressSuccess(true, responseData)),
                    put(actions.setUpdateClientAddressFailed(false)),
                ]);

            } else {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                    put(actions.setUpdateClientAddressFailed(true)),
                ]);
            }
            yield put(actions.getClientAddress({ clientAccessToken }))
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressFailed(true, responseData.message)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setUpdateClientAddressLoading(false)),
                    put(actions.setUpdateClientAddressFailed(true)),
                    put(actions.setUpdateClientAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setUpdateClientAddressLoading(false)),
            put(actions.setUpdateClientAddressSuccess(false)),
            put(actions.setUpdateClientAddressFailed(true)),
        ]);
    }
}

function* setDefaultAddressSaga({ payload: { id, clientAccessToken } }) {
    yield all([
        put(actions.setSetDefaultAddressLoading(true)),
        put(actions.setSetDefaultAddressSuccess(false)),
        put(actions.setSetDefaultAddressFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("SET_DEFAULT_ADDRESS"),
            clientAccessToken,
            queryString.stringify({ id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.message,
                        "success",
                        uid()
                    )
                );
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressSuccess(true, responseData)),
                    put(actions.setSetDefaultAddressFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                    put(actions.setSetDefaultAddressFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressFailed(true, responseData.message)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setSetDefaultAddressLoading(false)),
                    put(actions.setSetDefaultAddressFailed(true)),
                    put(actions.setSetDefaultAddressSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Address Book",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setSetDefaultAddressLoading(false)),
            put(actions.setSetDefaultAddressSuccess(false)),
            put(actions.setSetDefaultAddressFailed(true)),
        ]);
    }
}// address book end



function* savedCardsSaga({ payload: { client_id, clientAccessToken } }) {
    yield all([
        put(actions.setSavedCardsLoading(true)),
        put(actions.setSavedCardsSuccess(false)),
        put(actions.setSavedCardsFailed(false)),
    ]);
    try {
        const response = yield call(
            postApiToken,
            apiUrl("SAVED_CARDS"),
            clientAccessToken,
            queryString.stringify({ client_id: client_id }),

        );
        if (response.status === 200) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.data) {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsSuccess(true, responseData)),
                    put(actions.setSavedCardsFailed(false)),
                ]);
            } else {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsSuccess(false)),
                    put(actions.setSavedCardsFailed(true)),
                ]);
            }
        } else if (response && response.status === 422) {
            const responseData = yield call([response, response.json]);
            if (responseData && responseData.message) {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsFailed(true, responseData.message)),
                    put(actions.setSavedCardsSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        responseData.error,
                        "danger",
                        uid()
                    )
                );
            } else {
                yield all([
                    put(actions.setSavedCardsLoading(false)),
                    put(actions.setSavedCardsFailed(true)),
                    put(actions.setSavedCardsSuccess(false)),
                ]);
                yield put(
                    generalActions.addToast(
                        "Payment Method",
                        "Something went wrong!",
                        "danger",
                        uid()
                    )
                );
            }
        }
    } catch (error) {
        yield all([
            put(actions.setSavedCardsLoading(false)),
            put(actions.setSavedCardsSuccess(false)),
            put(actions.setSavedCardsFailed(true)),
        ]);
    }
}