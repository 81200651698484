import React, { useState, useEffect } from "react";
import './index.scss';
import { Link } from "react-router-dom";
import $ from 'jquery';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import ScheduleADemo from "../../components/ScheduleADemo";
import config from "../../config/config";

const options = {
  responsiveClass: true,
  nav: true,
  autoplay: true,
  responsive: {
      0: {
          items: 1,
      },
      480: {
          items: 2,
      },
      767: {
          items: 3,
      }
  },
};

const testimonial = {
  responsiveClass: true,
  nav: false,
  autoplay: false,
  items: 1,
  autoplay: true,
  responsive: {
    0: {
        items: 1,
    },
    480: {
        items: 1,
    },
    767: {
        items: 1,
    }
},
};

function Home() {

    useEffect(() => {
      var header = $(".header");
      $('.hambourger-menu').on('click',function() {
        $('body').toggleClass("menu-active")
      });
    
      $('.header .navigation ul li a').on('click',function() {
        $('body').removeClass("menu-active");
      });
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 100 && $(this).width()) {
            header.addClass("sticky-header");
        } else {
            header.removeClass('sticky-header');
        }
    });	  

      $(".tab_content").hide();
      $(".tab_content:first").show();
      $("ul.tabs li").click(function() {
        $(".tab_content").hide();
        var activeTab = $(this).attr("rel"); 
        $("#"+activeTab).fadeIn();		
      
        $("ul.tabs li").removeClass("active");
        $(this).addClass("active");
  
      $(".tab_drawer_heading").removeClass("d_active");
      $(".tab_drawer_heading[rel^='"+activeTab+"']").addClass("d_active");
      });
    $(".tab_drawer_heading").click(function() {
        $(".tab_content").hide();
        var d_activeTab = $(this).attr("rel"); 
        $("#"+d_activeTab).fadeIn();
      
      $(".tab_drawer_heading").removeClass("d_active");
        $(this).addClass("d_active");
      
      $("ul.tabs li").removeClass("active");
      $("ul.tabs li[rel^='"+d_activeTab+"']").addClass("active");
      });
  
    $('ul.tabs li').last().addClass("tab_last");

    if ($(window).width() < 767) {
      $('.slidesubmenu > a').on("click", function (e) {
        e.preventDefault();
        $(this).toggleClass('sldm-open');
        $(this).parent().find('.submenu').slideToggle(450);
    });
    }

    
      $('a[href*="#"]:not([href="#"]):not([href="#show"]):not([href="#hide"])').click(function() {
        if (window.location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && window.location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html,body').animate({
              scrollTop: target.offset().top + -160
            }, 1000);
            return false;
          }
        }
      });
    
   

      }, []);
  return (
<div className="landingpage-container">
    <div className="landing-page">
      <div className=" container fc">
          <div className="header">
            <div className="header-inner">
          <a href={config.LOGO_LINK} className="logo">
                <span>
                  <img src={require("../../assets/images/logo.svg")} alt="KitchenDesign365" />
                </span>
          </a>

          <div className="navigation">
            <ul>
              <li className="slidesubmenu">
                <a href="#solutions-block">Solutions</a>
                {/* <ul className="submenu">
                  <li><Link href="#">Solutions</Link></li>
                  <li><Link href="#">Solutions</Link></li>
                  <li><Link href="#">Solutions</Link></li>
                  <li><Link href="#">Solutions</Link></li>
                </ul> */}
              </li>
              <li><a href="#work-block">Our Work</a></li>
              <li><a href="#plans-block">Plans</a></li>
              <li><a href="#about-ninja-block">About Us</a></li>
              <li className="signin"><a href={config.SIGN_IN_LINK} className="btn back">SIGN IN</a></li>
            </ul>
          </div>

          <div className="right-button">
          <a href={config.SIGN_IN_LINK} className="btn back">SIGN IN</a>
          <a href="https://app.kitchen365.com/signup" type="button" className="btn btn-b-blue save-draft book-demo-top">Get Started</a>

          </div>

          <div className="nav-mobile">
            <a href={void(0)} className="hambourger-menu">
              <span></span>
              <span></span>
              <span></span>
            </a>
          </div>
          </div>
          </div>
      </div>

      <div className="banner-container">
        <div className="container fc">
          <div className="banner-inner-section">
            <div className="banner-left-content">
              <h1>Kitchen Design Services</h1>
              <p>Our 2020 Design Services Deliver Fast, Quality Designs At Unbeatable Prices.</p>
              <p>For As Low As $25/ Kitchen Design</p>
              <div className="right-button">
              <a href="https://app.kitchen365.com/signup" className="btn back book-demo orange-btn">Get Started</a>

              <a href="#plans-block" type="button" className="btn btn-b-blue save-draft view-plan">VIEW PLANS</a>
              </div>
            </div>

            <div className="banner-video">
            <div className="video-block">
            <iframe type="text/html" src="https://www.youtube.com/embed/bnwtvu-ahro?autoplay=0"></iframe>
            </div>
            </div>
          </div>
        </div>
      </div>

      <section className="main-content">
        <section className="whyus-main-section">
          <div className="container">
            <div className="whyus-block">
              <div className="left-content">
                <h3>WHY US?</h3>
                <p>We provide end to end solution for your kitchen Design businesses.</p>
              </div>

              <ul className="whyus-listing">
                <li>
                  <i><img src={require("../../assets/images/whyus-icon1.svg")} alt="Fast & Reliable" /></i>
                  <h5>Fast & Reliable</h5>
                </li>
                <li>
                  <i><img src={require("../../assets/images/whyus-icon2.svg")} alt="Flexible & Scalable" /></i>
                  <h5>Flexible & Scalable</h5>
                </li>
                <li>
                  <i><img src={require("../../assets/images/whyus-icon3.svg")} alt="Best Price Guaranteed" /></i>
                  <h5>Best Price Guaranteed</h5>
                </li>
                <li>
                  <i><img src={require("../../assets/images/whyus-icon4.svg")} alt="Professional Designers" /></i>
                  <h5>Professional Designers</h5>
                </li>
              </ul>
            </div>
          </div>
        </section>

        <section className="kitchen-design-solution" id="solutions-block">
          <div className="container">
            <div className="kitchen-design-inner">
            <h2>Solutions For Kitchen Design</h2>
            <p>From pricing estimates to 3D designs to blueprints, we provide you with everything you need to get your project going within 1-2 business days and up to three different iterations of the design.</p>
                
            <div className="clearfix">
            <ul className="tabs">
              <li className="active" rel="tab1">2020 3D Kitchen Design</li>
              <li rel="tab2">3D Render PDFs</li>
              <li rel="tab3">Project Blueprints</li>
              <li rel="tab4">Quotation SKU Sheets</li>
              <li rel="tab5">2020 .Kit Files</li>
            </ul>

            <div className="tab_container">
            <h3 className="d_active tab_drawer_heading" rel="tab1">2020 3D Kitchen Design</h3>
            <div id="tab1" className="tab_content">
              <div className="kitchen-solution">
                <div className="kitchen-left-content">
                  <img src={require("../../assets/images/tab-img01.png")} alt="Kitchen Design" />
                </div>
                <div className="kitchen-right-content">
                  <h4>Solutions For Kitchen Design</h4>
                  <p>We create a beautiful, interactive, 3D design of the kitchen so you and your customers can see what it’s like to walk around the kitchen.</p>
                  <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">Get Started</a>

                </div>
              </div>
            </div>
            
            <h3 className="tab_drawer_heading" rel="tab2">3D Render PDFs</h3>
            <div id="tab2" className="tab_content">
            <div className="kitchen-solution">
                <div className="kitchen-left-content">
                  <img src={require("../../assets/images/tab-img02.png")} alt="Kitchen Design" />
                </div>
                <div className="kitchen-right-content">
                  <h4>3D Render PDFs For Kitchen Design</h4>
                  <p>3D Render PDFs include detailed illustrations of the kitchen designs that can be easily downloaded and shared with customers to get a sense of the look and feel of the kitchen.</p>
                  <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">Get Started</a>
                </div>
              </div>
            </div>
            
            <h3 className="tab_drawer_heading" rel="tab3">Project Blueprints</h3>
            <div id="tab3" className="tab_content">
            <div className="kitchen-solution">
                <div className="kitchen-left-content">
                  <img src={require("../../assets/images/tab-img03.png")} alt="Kitchen Design" />
                </div>
                <div className="kitchen-right-content">
                  <h4>Project Blueprints For Kitchen Design</h4>
                  <p>Project blueprints are incredibly detailed, showing you precise measurements for the room and products.</p>
                  <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">Get Started</a>
                </div>
              </div>
            </div>
            
            <h3 className="tab_drawer_heading" rel="tab4">Quotation SKU Sheets</h3>
            <div id="tab4" className="tab_content">
            <div className="kitchen-solution">
                <div className="kitchen-left-content">
                  <img src={require("../../assets/images/tab-img04.png")} alt="Kitchen Design" />
                </div>
                <div className="kitchen-right-content">
                  <h4>Quotation SKU Sheets</h4>
                  <p>Quotation SKU Sheets include all products and give you an estimate of what the kitchen project will cost.</p>
                  <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">Get Started</a>

                </div>
              </div>
            </div>
            <h3 className="tab_drawer_heading" rel="tab5">2020 .Kit Files</h3>
            <div id="tab5" className="tab_content">
            <div className="kitchen-solution">
                <div className="kitchen-left-content">
                  <img src={require("../../assets/images/tab-img05.png")} alt="Kitchen Design" />
                </div>
                <div className="kitchen-right-content">
                  <h4>2020 .Kit Files</h4>
                  <p>2020 .kit files include specifications and 3D renderings to see the kitchen’s precise measurements and overall feel.</p>
                  <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">Get Started</a>

                </div>
              </div>
            </div>
          </div>
          </div>
          </div>

          </div>
        </section>

        <section className="work-container common-slider-block" id="work-block">
          <div className="container">
            <h2>Our Work</h2>
            <p className="description-content">We’ve completed over 20,000 designs in a wide variety of styles from Contemporary to Farmhouse to Craftsman.</p>
            <OwlCarousel className='owl-theme' {...options} loop margin={0} nav>
            <div className='item'>
                <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img1.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img2.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img3.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img4.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img5.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img6.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img7.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img8.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img1.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img2.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img3.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img4.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img5.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div> 
            <div className='item'>
                 <div className="work-inner-block">
                  <i><img src={require("../../assets/images/project-img6.png")} alt="Project" /></i>
                  {/* <h5>Project Name Here</h5> */}
                </div>
            </div>
        </OwlCarousel>
        {/* <a className="btn back book-demo orange-btn" href="">All Our Work</a> */}
          </div>
        </section>   


       
        <section className="trust-kitchen-design" id="about-ninja-block">
          <div className="container">
            <div className="kitchen-design-block">
              <div className="left-design-block">
                <h2>Meet Trusted Kitchen Design Ninjas</h2>
                <p>Kitchen365 has built a team of over 100 professionally trained 2020 kitchen designers that make the sales process as easy as it can be. Our 2020 Design Services deliver fast, quality designs at unbeatable prices.</p>
                <div className="right-button">
                <a href="https://app.kitchen365.com/signup" className="btn back book-demo">Get Started</a>
                <a href="#plans-block" type="button" className="btn btn-b-blue save-draft view-plan">VIEW PLANS <i><img src={require("../../assets/images/right-arrow.png")} alt="" /></i></a>
                </div>
              </div>
              <div className="right-design">
                <i><img src={require("../../assets/images/ninja-img.svg")} alt="Ninja" /></i>
              </div>
            </div>
          </div>
        </section>


        <section className="design-view-container">
          <div className="container">
            <div className="design-inner-section">
              <div className="left-design-view inside-design">
              <a href="https://panorama.2020.net/view/cmw7npodx0frhethregew/" target="_blank">
              <img src={require("../../assets/images/3d-render-img.png")} alt="CLICK TO SEE AN INTERACTIVE 3D RENDER" />
              <span>CLICK TO SEE AN INTERACTIVE 3D RENDER</span>
              </a>
              </div>
              <div className="right-design-view inside-design">
              <a href="https://www.kitchen365.com/pdf/2020%20Design-2D-Sample.pdf" target="_blank">
              <img src={require("../../assets/images/blueprint-img.png")} alt="DOWNLOAD OUR 2D DESIGN PDF WITH BLUEPRINTS" />
              <span>DOWNLOAD OUR 2D DESIGN PDF WITH BLUEPRINTS</span>
              </a>
              </div>
            </div>
          </div>
        </section>


        <section className="howit-work-section">
          <div className="container">
            <h2>How Does It Work?</h2>
            <p>We recommend helping your customers measure their kitchen (<a href="https://www.youtube.com/embed/R8WU445_DXo" target="_blank">this video</a> will help) and following these six steps to get your design completed:</p>
            <ul className="howwork-block">
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img1.png")} alt="Create An Account With Us" />
                  </div>
                  <div className="work-title">
                    <span>1</span>
                    <h5>Create an Account With Us</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img2.png")} alt="Access The Portal & Put Design Requirements" />
                  </div>
                  <div className="work-title">
                    <span>2</span>
                    <h5>Access the Portal and Enter Design Requirements</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img3.png")} alt="Select The Desired Plan & Make The Payment" />
                  </div>
                  <div className="work-title">
                    <span>3</span>
                    <h5>Select a Payment Plan & Make a Payment</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img4.png")} alt="Hurray ! Now Our Designers Will Take Care Of The Request" />
                  </div>
                  <div className="work-title">
                    <span>4</span>
                    <h5>Hurray! Now Our Designers Will Take Care of the Design</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img5.png")} alt="Request For Revisions If Required" />
                  </div>
                  <div className="work-title">
                    <span>5</span>
                    <h5>Request Any Needed Revisions</h5>
                  </div>
                </div>
              </li>
              <li>
                <div className="work-listing">
                  <div className="listingbg-img">
                    <img src={require("../../assets/images/work-img6.png")} alt="Final Output In Desired Format" />
                  </div>
                  <div className="work-title">
                    <span>6</span>
                    <h5>Final Output</h5>
                  </div>
                </div>
              </li>
            </ul>
            {/* <a className="btn back book-demo orange-btn" href="">GET STARTED</a> */}
          </div>
        </section>


        <section className="schedule-demo-section form-block" id="schedule-demo">
          <div className="schedule-bg">

          </div>
          <div className="container">
            <ScheduleADemo />
          </div>
        </section>

        <section className="design-plans-section" id="plans-block">
          <div className="container">
            <h2>The Right Plan To Match Your Design Needs</h2>
            <p>We have three payment plans based on the number of designs you need.</p>

            <div className="design-inner-plans">
              <ul>
                <li>
                  <div className="plans-inner-block">
                    <h4>Kitchen</h4>
                    <strong><sub>$</sub>25</strong>
                    <span>Per Design</span>
                    <ul>
                      <li>3 Iterations</li>
                      <li>Includes 2020 .KIT File</li>
                      <li>Blueprints & Floorplan</li>
                      <li>CSV File for Easy Import</li>
                    </ul>
                    
                    <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">GET STARTED</a>
                    
                  </div>
                </li>

                <li>
                  <div className="plans-inner-block">
                    <h4>Full House</h4>
                    <strong><sub>$</sub>50</strong>
                    <span>Per Design</span>
                    <ul>
                      <li>3 Iterations</li>
                      <li>Includes 2020 .KIT File</li>
                      <li>Full 3D Render Presentation</li>
                      <li> Blueprints & Floorplan</li>
                      <li>CSV File for Easy Import</li>
                    </ul>
                    
                    <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">GET STARTED</a>
                  </div>
                </li>

                <li>
                  <div className="plans-inner-block">
                    <h4>Retainer</h4>
                    <strong><sub>$</sub>1400</strong>
                    <span>Per Month</span>
                    <ul>
                      <li>Dedicated Designer</li>
                      <li>60 Designs a Month</li>
                      <li>Kitchen & Full House Designs</li>
                      <li>2020 .KIT File, Blueprints & Floorplan</li>
                      <li>3D Render & .CSV File</li>
                    </ul>
                    
                    <a className="btn back book-demo orange-btn" href="https://app.kitchen365.com/signup">GET STARTED</a>

                    
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </section>


      <section className="testimonial-container common-slider-block">
        <div className="container">
          <h2>Satisfied Clients Is Our Ultimate Goal</h2>
          <p className="test-descr">Hear what our customers think!</p>
          <div className="client-slider">
          <div className="quote-block">
            <i><img src={require("../../assets/images/quotes.png")} alt="Quotes" /></i>
          </div>
          <OwlCarousel className='owl-theme' {...testimonial} loop margin={0} nav>
            <div className='item'>
                <div className="client-testimonials">
                  {/* <i className="author-img"><img src={require("../../assets/images/author-img.png")} alt="Author" /></i> */}
                  {/* <i><img src={require("../../assets/images/star-img.png")} alt="Rating" /></i> */}
                  <p>Our company uses Kitchen365 2020 Design Services for just about every project we work on. They have a fast hard-working crew that can accommodate the large number of projects we work on per year. The designs we receive are the best out there. We get so many compliments, not only from our clients but from other designers as well. This is a great company to work with!</p>
                  <div className="author-details">
                    <strong>J. WRIGHT</strong>
                    {/* <span>Submitted 07 Nov 2021</span> */}
                  </div>
                </div>
            </div>

            <div className='item'>
                <div className="client-testimonials">
                  {/* <i className="author-img"><img src={require("../../assets/images/author-img.png")} alt="Author" /></i> */}
                  {/* <i><img src={require("../../assets/images/star-img.png")} alt="Rating" /></i> */}
                  <p>The guys did a good job. We are all so overwhelmed right now we haven’t had the time to further explore but will very soon.</p>
                  <div className="author-details">
                    <strong>R. STANGO</strong>
                    {/* <span>Submitted 07 Nov 2021</span> */}
                  </div>
                </div>
            </div>

            <div className='item'>
                <div className="client-testimonials">
                  {/* <i className="author-img"><img src={require("../../assets/images/author-img.png")} alt="Author" /></i> */}
                  {/* <i><img src={require("../../assets/images/star-img.png")} alt="Rating" /></i> */}
                  <p>We have been working with the Kitchen365 team for 3 months. They have been so helpful in executing quality, detailed kitchen designs for our cabinet company. The pricing for their services is affordable and the turnaround time is so fast. The communication with Kitchen365 has been great. If I have any questions, I know I can reach out to Pratik and get an answer right away. If anyone is looking for a design service, I would recommend trying Kitchen365. You won’t regret it.</p>
                  <div className="author-details">
                    <strong>A. JONES</strong>
                    {/* <span>Submitted 07 Nov 2021</span> */}
                  </div>
                </div>
            </div>

            <div className='item'>
                <div className="client-testimonials">
                  {/* <i className="author-img"><img src={require("../../assets/images/author-img.png")} alt="Author" /></i> */}
                  {/* <i><img src={require("../../assets/images/star-img.png")} alt="Rating" /></i> */}
                  <p>I appreciate your professionalism and follow-up, it is refreshing!!  Your drawings/designs are really good and the detail is spot on!</p>
                  <div className="author-details">
                    <strong>D. MIKELS</strong>
                    {/* <span>Submitted 07 Nov 2021</span> */}
                  </div>
                </div>
            </div>
        </OwlCarousel>
        </div>
        </div>
      </section>

      
      <section className="footer-container">
        <div className="footer-top">
          <div className="container">
            <div className="footer-top-outer">
              <div className="footer-column">
                <h4>Contact Details</h4>
                <p><span>Address:</span> 752 Winer Industrial Way, Suite G Lawrenceville GA 30046</p>
                <p><span>Phone:</span> <a href="tel:678-666-0628">678-666-0628</a></p>
                <p><span>Email:</span> <a href="mailto:design@kitchen365.com">design@kitchen365.com</a></p>
              </div>

              <div className="footer-column">
                <h4>Help Station</h4>
                <ul>
                  <li>
                    <a href="#solutions-block">Solutions</a>
                  </li>
                  <li>
                  <a href="#work-block">Our Work</a>
                  </li>
                  <li>
                  <a href="#plans-block">Plans</a>
                  </li>
                  <li>
                  <a href="#about-ninja-block">About Us</a>
                  </li>
                </ul>
              </div>

              {/* <div className="footer-column">
                <ul className="social-list">
                  <li>
                    <a href="https://www.facebook.com/kitchen365com">
                        <img src={require("../../assets/images/facebook-icon.svg")} alt="Facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/kitchen365com/">
                        <img src={require("../../assets/images/linkedin-icon.svg")} alt="Linkedin" />
                    </a>
                    </li>
                  <li>  
                    <a href="https://twitter.com/kitchen365com">
                        <img src={require("../../assets/images/twitter-icon.svg")} alt="Twitter" />
                    </a>
                    </li>
                    <li>  
                    <a href="https://www.instagram.com/kitchen.365/">
                        <img src={require("../../assets/images/instagram-icon.svg")} alt="instagram" />
                    </a>
                    </li>
                  <li>
                     <a href="https://www.pinterest.com/kitchen365/_created/">
                        <img src={require("../../assets/images/pinterest-icon.svg")} alt="Pinterest" />
                    </a>
                    </li>
                  <li> 
                    <a href="https://www.youtube.com/channel/UCalurDhpLOdbj-PlTpadcuQ">
                        <img src={require("../../assets/images/youtube-icon.svg")} alt="Youtube" />
                    </a>
                    </li>
                  
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
            <div className="container">
              <div className="footer-bottom-outer">
                <div className="footer-column footer-col1">
                   <p class="footer-site-info">COPYRIGHT © 2022, KITCHEN365 INC. ALL RIGHTS RESERVED.</p>
                </div>
                <div className="footer-column footer-col2">
                <div className="footer-nav">
                      <ul>
                        <li><a href="https://www.kitchen365.com/plans-and-pricing/">Plans and Pricing</a></li>
                        <li><a href="https://design.kitchen365.com/">Design Services</a></li>
                        <li><a href="https://www.kitchen365.com/faqs/">FAQs</a></li>
                        <li><a href="https://www.kitchen365.com/blog/">Blog</a></li>
                        <li><a href="https://www.kitchen365.com/resources/">Resources</a></li>
                        <li><a href="https://www.kitchen365.com/careers/">Careers</a></li>
                        {/* <li><a href="https://www.kitchen365.com/kbis/">KBIS 2022</a></li> */}
                      </ul>
                    </div>
                </div>
                <div className="footer-column footer-col3">
                    <ul className="social-icons">
                      <li><a href="https://www.facebook.com/kitchen365com"><img src={require("../../assets/images/facebook-img.svg")} alt="Facebook" /><img src={require("../../assets/images/facebook-img-h.svg")} alt="Facebook" className="hover-img"/></a></li>
                      <li><a href="https://www.linkedin.com/company/kitchen365com/"><img src={require("../../assets/images/linkedin-img.svg")} alt="Linkedin" /><img src={require("../../assets/images/linkedin-img-h.svg")} alt="Linkedin" className="hover-img"/></a></li>
                      <li className="twitter-img"><a href="https://twitter.com/kitchen365com"><img src={require("../../assets/images/twitter-img.svg")} alt="Twitter" /><img src={require("../../assets/images/twitter-img-h.svg")} alt="Twitter" className="hover-img"/></a></li>
                      <li className="instagram-img"><a href="https://www.instagram.com/kitchen.365/"><img src={require("../../assets/images/instagram-img.svg")} alt="Instagram" /><img src={require("../../assets/images/instagram-img-h.svg")} alt="Instagram" className="hover-img"/></a></li>
                      <li><a href="https://www.pinterest.com/kitchen365/_created/"><img src={require("../../assets/images/pinterest-img.svg")} alt="Pitenrest" /><img src={require("../../assets/images/pinterest-img-h.svg")} alt="Pitenrest" className="hover-img"/></a></li>
                      <li><a href="https://www.youtube.com/channel/UCalurDhpLOdbj-PlTpadcuQ"><img src={require("../../assets/images/youtube-img.svg")} alt="Youtube" /><img src={require("../../assets/images/youtube-img-h.svg")} alt="Youtube" className="hover-img"/></a></li>
                    </ul>
                </div>
              </div>
            </div>
        </div>
      </section>
      
    </div>
    </div>
  );
}

export default Home;



