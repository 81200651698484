/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import { actions as myRequestActions } from "../../redux/reducers/MyRequestReducer";
import { actions as designerRequestActions } from "../../redux/reducers/DesignerRequestReducer";
import { useHistory } from "react-router-dom";
import { actions as generalActions } from "../../redux/reducers/GeneralReducer";
import ShortUniqueId from "short-unique-id";
import "./index.scss";

function ViewRequestStatus({ data }) {
  const history = useHistory();
  const uid = new ShortUniqueId();
  const dispatch = useDispatch();
  const [cookies] = useCookies(["clientAccessToken"]);
  const { requestId, setUpdateStatusForMyRequest,clientRequestUpdateProcess } = useSelector(
    (state) => state.myRequest
  );

  const {isUpdatingStatus,updatedStatusResponse,
    isUpdateRequestFailed } = clientRequestUpdateProcess

  const { requestIdDesigner, myOpenRequestDataLoading, updateStatusFlag, updateAssigneeFlag } = useSelector(
    (state) => state.designerRequest
  );
  const { user } = useSelector((state) => state.auth);

  const [statusId, setStatusId] = useState('1');
  // const [selectStatusId, setSelectStatusId] = useState('1');
  const [statusSlug, setStatusSlug] = useState("");
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [assignee, setAssignee] = useState("1");

  const changeStatus = (e) => {
    setStatusId(e.target.value);
    for (var i = 0; i < data["request_status_dropdown"].length; i++) {
      if (~~e.target.value === data["request_status_dropdown"][i]["id"]) {
        setStatusSlug(data["request_status_dropdown"][i]["slug"]);
      }
    }
  };

  useEffect(() => {
    if (data["designer"] && data["designer"].length) {
      setAssignee(1);
    }
    if (data && data["request_status"]) {
      setStatusId(1);
    }
  }, [data]);

  useEffect(() => {
    let isResponseAvailable = isUpdatingStatus === false 
    && Object.keys(updatedStatusResponse).length > 0

    if(isResponseAvailable && updatedStatusResponse.statusChangedTo === 'approved'){
      dispatch(myRequestActions.resetApproveStatus())
      history.push(`/app/client/myrequests/review-submit/${btoa(updatedStatusResponse.requestedId)}?onApprove=true`);
    }
  },[isUpdatingStatus,isUpdateRequestFailed,updatedStatusResponse])

  useEffect(() => {
    if (myOpenRequestDataLoading) {
    } else {
      setMinutes("");
      setHours("");
    }
  }, [myOpenRequestDataLoading]);
  useEffect(() => {
    if (setUpdateStatusForMyRequest || updateStatusFlag) {
      setStatusId(1);
    } 
  }, [setUpdateStatusForMyRequest, updateStatusFlag]); 


  useEffect(() => {
    if (updateAssigneeFlag) {
      setAssignee(1);
      dispatch(designerRequestActions.clearUpdateAssigneeFlag());
    } 
  }, [dispatch, updateAssigneeFlag]); 

  const updateStatus = () => {
    if(statusId ===1){

    }else {
    if (user.group_id === 3) {
      dispatch(
        designerRequestActions.updateStatusDesigner(
          requestIdDesigner,
          statusId,
          cookies.clientAccessToken
        )
      );
    } else {
      dispatch(
        myRequestActions.updateStatusClient(
          requestId,
          statusId,
          statusSlug,
          cookies.clientAccessToken
        )
      );
    }
  }
  };
  const updateWorkLog = () => {
    if (hours || minutes) {
      let tempMinutes = Number(hours) * 60;
      tempMinutes = Number(tempMinutes) + Number(minutes);
      dispatch(
        designerRequestActions.updateWorkLog(
          requestIdDesigner,
          tempMinutes,
          data["client_id"],
          cookies.clientAccessToken
        )
      );
    } else {
      dispatch(
        generalActions.addToast(
          "Required field",
          "Please enter value in worklog",
          "danger",
          uid()
        )
      );
    }
  };
  const changeAssignee = (e) => {
    setAssignee(e.target.value);
  };
  const updateAssignee = () => {
    if(assignee === 1){

    }else{
    dispatch(
      designerRequestActions.updateAssignee(
        requestIdDesigner,
        assignee,
        data["client_id"],
        cookies.clientAccessToken
      )
    );
      }
  };

  const changeHours = (e) => {
    setHours(e.target.value);
  };
  const changeMinutes = (e) => {
    if (e.target.value > 59) {
      setMinutes(59);
    } else {
      setMinutes(e.target.value);
    }
  };
  const renderUpdateRequest = () => {
    if (data && data["request_status"]["status"][0]["slug"] !== "approved" && data["request_status"]["status"][0]["slug"] !== "closed") {
      return (
        <li>
          <span className="status-list-title">Change Status</span>
          <div className="change-status">
            <select
              value={statusId}
              onChange={changeStatus}
              className="select-box input-box"
              placeholder ="select"
            >
              <option value={1} >Select</option>
              {data &&
                data["request_status_dropdown"] &&
                Object.keys(data["request_status_dropdown"]).map(
                  (key, index) => {
                    return (
                      <option
                        key={key}
                        value={data["request_status_dropdown"][key]["id"]}
                      >
                        {data["request_status_dropdown"][key]["name"]}
                      </option>
                    );
                  }
                )}
            </select>
            <button onClick={updateStatus} className="btn btn-black update">
              Update
            </button>
          </div>
        </li>
      );
    }
  };
  const renderAssignedToPart = () => {
    if (data && data["position"] === "Project Manager") {
      if (data && data["designer"] && data["designer"].length > 0) {
        return (
          <li>
            <span className="status-list-title">Assigned to</span>
            <div className="change-status">
              <select
              value={assignee}
                onChange={changeAssignee}
                className="select-box input-box"
              >
                <option value={1} >Select</option>
                {data &&
                  data["designer"] &&
                  Object.keys(data["designer"]).map((key, index) => {
                    return (
                      <option
                        key={key}                        
                        value={data["designer"][key]["id"]}
                      >
                        {data["designer"][key]["name"]}
                      </option>
                    );
                  })}
              </select>
              <button
                type="button"
                onClick={updateAssignee}
                className="btn btn-black update"
              >
                Update
              </button>
            </div>
          </li>
        );
      } else {
        return (
          <li>
            <span className="status-list-title">Assigned to</span>
            <div className="change-status">
              <select
                onChange={changeAssignee}
                className="select-box input-box"
              >
              </select>
              <button
                type="button"
                onClick={updateAssignee}
                className="btn btn-black update"
                disabled
              >
                Update
              </button>
            </div>
          </li>
        );
      }
    }
  };
  const renderDesignerPart = () => {
    if (
      user.group_id === 3 &&
      data &&
      data["request_status"] &&
      data["request_status"]["status"][0]["slug"] !== "approved"
    ) {
      return (
        <ul className="request-status-list cf">
          <li className="log-work-box">
            <span className="status-list-title">Log Work</span>
            <div className="log-work-update change-status">
              <input
                value={hours}
                className="input-box hours"
                type="number"
                placeholder="HH"
                min="0"
                onChange={changeHours}
              />
              <span className="seprater">:</span>
              <input
                value={minutes}
                className="input-box minute"
                type="number"
                placeholder="MM"
                min="0"
                max="59"
                onChange={changeMinutes}
              />
              <button
                type="button"
                onClick={updateWorkLog}
                className="btn btn-black update"
              >
                Update
              </button>
            </div>
          </li>
          {renderAssignedToPart()}
        </ul>
      );
    }
  };
  return (
    <div className="viewrequeststatus cf">
      <h3 className="request-status-title">Request Status</h3>
      <ul className="request-status-list cf">
        <li>
          <span className="status-list-title">Current Status</span>
          <span
            className={
              data && data["request_status"]
                ? "request-status " +
                  data["request_status"]["status"][0]["name"]
                    .toLowerCase()
                    .replace(/\s+/g, "-")
                : ""
            }
          >
            {data && data["request_status"]
              ? data["request_status"]["status"][0]["name"]
              : ""}
          </span>
        </li>
        <li>
          <span className="status-list-title">Revisions</span>
          <span className="revisions-no">
            {data && data["request_status"]
              ? data["request_status"]["revision"]
              : ""}
          </span>
        </li>
        <li>
          <span className="status-list-title">Revision Due To Design Error</span>
          <span className="revisions-no">
            {data && data["request_status"]
              ? data["request_status"]["revision_design_error"]
              : ""}
          </span>
        </li>
        <li className="timespent">
          <span className="status-list-title">Time Spent</span>
          <span className="time">
            {data && data["request_status"]
              ? data["request_status"]["time_spent"]
              : ""}
          </span>
        </li>
        {renderUpdateRequest()}
      </ul>
      {renderDesignerPart()}
    </div>
  );
}

export default ViewRequestStatus;
