const dev = {
  API_ENDPOINT: "http://localhost:8000/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6Le7jAgaAAAAAMqw2g6HrbGb-p1PnwqQO9dDp_59",
  LOGO_LINK: "http://localhost:3000/",
  SIGN_IN_LINK: "http://localhost:3000/login",
};

const stage = {
  API_ENDPOINT: "https://2020pmsapi.commercepandit.com/",
  AUTHORIZATION_TYPE: "Bearer",
  LOGO_LINK: "https://www.kitchen365.com/",
  SIGN_IN_LINK: "http://2020pmsfront.commercepandit.com/login",
  // RECAPTCHA_SITE_KEY: "6LeyuewZAAAAAPzVWBaWQtoX1cyuY1FpHwS1Czhd"
};

const prod = {
  API_ENDPOINT: "https://api.kitchen365.com/",
  AUTHORIZATION_TYPE: "Bearer",
  RECAPTCHA_SITE_KEY: "6LeyuewZAAAAAPzVWBaWQtoX1cyuY1FpHwS1Czhd",
  LOGO_LINK: "https://www.kitchen365.com/",
  SIGN_IN_LINK: "https://app.kitchen365.com/",
};

function getConfig() {
  if (process.env.NODE_ENV === "production") {
      return prod;
  } else {
      if (process.env.REACT_APP_ENV && process.env.REACT_APP_ENV === "staging") {
          return stage;
      }
      return dev;
  }
}

const config = getConfig();

export default config;