import React, { useState, memo } from 'react'
import { useSelector } from "react-redux";
import ChangePassword from './ChangePassword'

function ContactInformation({ handleToggleUserContactInformation }) {
    const { myAccountReducer:{getAccountInfoResponse: { data: { client_info = {} } = {} } = {}} } = useSelector((state) => state);
    const [changePasswordShow, setChangePasswordShow] = useState(false)

    const handleToggleChangePassword = () => {
        setChangePasswordShow(!changePasswordShow)
    }
    return (
        <div className='left-info-block account-info-contact'>
            <div className='inner-info-block'>
                <div className='info-title' >
                    <h4>Contact Information</h4>
                    {!changePasswordShow && <i onClick={handleToggleUserContactInformation}><img src={require("../../../../assets/images/edit-icon.png")} alt="Edit" /></i>
                    }  </div>
                {!changePasswordShow && <> <ul className='contact-info'>
                    <li><label>Name:</label> {client_info.name}</li>
                    <li><label>Email:</label> {client_info.email}</li>
                    <li><label>Phone:</label> {client_info.phone}</li>
                    <li><label>Company:</label> {client_info.company_name}</li>
                </ul>
                    <a href='#' className='account-link' onClick={handleToggleChangePassword}>Change Password</a>
                </>}
                {changePasswordShow && <ChangePassword
                    handleToggleChangePassword={handleToggleChangePassword}
                />}
            </div>
        </div>
    )
}

export default memo(ContactInformation)
