import { getBase64DataFromUrl } from "../redux/apis";
import { actions as generalActions } from '../redux/reducers/GeneralReducer'
import store from '../redux/store'

export const convertUTCToLocale = (date) => {
  const dateOptions = { day: "numeric", month: "short", year: "numeric" };
  const timeOptions = { hour12: true, hour: "2-digit", minute: "2-digit" };
  const localeRegion = "en-GB";

  try {
    if (new Date(date) instanceof Date && !isNaN(new Date(date))) {
      let time = new Date(date)
        .toLocaleTimeString(localeRegion, timeOptions)
        .toUpperCase();
      let [day, month, year] = new Date(date)
        .toLocaleDateString(localeRegion, dateOptions)
        .split(" ");

      return {
        time,
        date: `${day} ${month}, ${year}`,
        dateAndTime: `${day} ${month}, ${year} \xa0 ${time}`,
      };
    }
    // throw new Error(new Date(date));
  } catch (e) {
    console.error("Error while parsing date : ", e);
  }
};

export const increaseDateByDays = (givenDate, incrementalDays) => {
  let todaysDate = new Date();
  let providedDate = new Date(givenDate);
  let diffrentBetweenDates = Math.ceil(
    (providedDate - todaysDate) / (1000 * 3600 * 24)
  );

  if (diffrentBetweenDates > 0 && diffrentBetweenDates < incrementalDays) {
    providedDate.setDate(
      providedDate.getDate() + (incrementalDays - diffrentBetweenDates)
    );
    return providedDate;
  }

  if (diffrentBetweenDates < 0) {
    providedDate.setDate(
      providedDate.getDate() +
      (Math.abs(diffrentBetweenDates) + incrementalDays)
    );
    return providedDate;
  }

  providedDate.setDate(providedDate.getDate() + incrementalDays);
  return providedDate;
};

export const quillOptionsCongif = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ header: [1, 2, 3, false] }],
      [{ align: [] }, { indent: "-1" }, { indent: "+1" }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "clean"],
    ],
  },
  formats: [
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'header',
    'list', 'bullet', 'indent', 'align',
    'link'
  ],
};

export const getFileTypeIcon = (fileType) => {
  switch (fileType) {
    case "image/png": {
      return `${require("../assets/images/png.png")}`;
    }
    case "image/jpeg": {
      return `${require("../assets/images/jpeg.png")}`;
    }
    case "application/pdf": {
      return `${require("../assets/images/pdf.png")}`;
    }

    case "application/octet-stream": {
      return `${require("../assets/images/kit.png")}`;
    }

    default: {
      return `${require("../assets/images/file.png")}`;
    }
  }
};

export const downloadFile = (fileUrl, filename, cookie) => {
  getBase64DataFromUrl(fileUrl, cookie)
    .then((url) => {
      const link = document.createElement("a");
      link.href = url?.data?.data[0];
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    })
    .catch((error) => console.log("Error : ", error));
};

export const convertStringToHtml = (stringToBeParsed) => {
  const parser = new DOMParser();
  return parser.parseFromString(stringToBeParsed, "text/html").body.innerHTML
}

export const validDateChecker = date => new Date(date) instanceof Date && !isNaN(new Date(date))
export const showToast = (title, message, type) => store.dispatch(generalActions.addToast(title, message, type));


/** Error getFieldCSSClasses
*  Class Name managed by This function   
 * @param {Boolean} touched  Label Pass Boolean Value.
 * @param {String} errors htmlFor Pass String Value.
 * @returns Class Name
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 *  Notes:- Dec 10 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
*/

export const getFieldCSSClasses = ({ touched, errors }) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};
