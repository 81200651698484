import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Input from 'react-phone-input-2';
import { getFieldCSSClasses } from '../../../../Utils/utils';
import { Label } from '../../ElementComponents';
import { Error } from '../../CustomComponents';
import 'react-phone-input-2/lib/style.css';

/**
 * This is  Phone Input function Component. 
 * @param {Object} setting  Setting  Pass Object Value.
 * @param {String} value Value Pass String Value.
 * @param {Function} onChange OnChange Pass Function Value.
 * @param {Function} onBlur OnBlur Pass Function Value.
 * @param {Boolean} touched Touched Pass Boolean Value.
 * @param {String} errors Errors Pass String Value.
 * @returns  Phone Input HTML Element
 * @author Vishal Gohel <vishal.gohel@commercepundit.com>
 * 
 *  Notes:- Dec 10 2021 (Vishal Gohel <vishal.gohel@commercepundit.com>)  Component Created
 */

const PhoneInput = (props) => {
    const { setting: { key, type, name, placeholder, label, htmlFor, required, className },
        value, onChange, onBlur, touched, errors
    } = props
    return (
        <>
            {label && <Label label={label} htmlFor={htmlFor} required={required} />}
            <Input
                inputProps={{
                    name: name,
                    required: true,
                    // autoFocus: true,
                }}
                country={props.country}
                placeholder={placeholder}
                type={type}
                value={value}
                onChange={(v, c, e, f) => onChange(v, c, e, f)}
                onBlur={onBlur}
                inputClass={`${className ? className : ""} ${getFieldCSSClasses({ touched, errors })}`}
                disabled={props.disabled}
            />
            {touched && <Error label={label} touched={touched} errors={errors} />}
        </>
    )
}

PhoneInput.propTypes = {
    setting: PropTypes.shape({
        key: PropTypes.string,
        type: PropTypes.string,
        name: PropTypes.string,
        placeholder: PropTypes.string,
        className: PropTypes.string,
        label: PropTypes.string,
        htmlFor: PropTypes.string,
        required: PropTypes.bool,
    }),
    touched: PropTypes.bool,
    errors: PropTypes.string,
    country: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
};

PhoneInput.defaultProps = {
    setting: {
        key: "",
        type: "",
        name: "",
        placeholder: "",
        className: "",
        label: "",
        htmlFor: "",
        required: false,
    },
    touched: false,
    errors: "",
    country: "",
    value: "",
    onChange: () => { },
    onBlur: () => { },
}


export default memo(PhoneInput)